import { karrotBridge } from '@src/bridge/bridge'
import { clientEnvironment } from '@src/bridge/utils'
import { STAGE } from '@src/constants'
import { logLevel } from '@src/log/logLevel'
import { getAllExternalAccessValues } from '@src/store/externalAccessValues'

import { type LocalMapEventName } from './eventNames/types'

export const objectKeysToSnake = (object: Record<string, any>) => {
  return Object.keys(object).reduce((acc, key) => {
    return {
      ...acc,
      [key.replace(/([A-Z])/g, '_$1').toLowerCase()]: object[key],
    }
  }, {})
}

export const karrotBridgeLog = (
  name: LocalMapEventName,
  params: Record<string, any>
) => {
  const externalParams = getAllExternalAccessValues()
  const snakeCaseParams = objectKeysToSnake({
    ...externalParams,
    ...params,
  })

  if (clientEnvironment.isWeb) {
    logLevel.log({
      target: 'KARROT',
      name,
      params: snakeCaseParams,
    })
  } else {
    if (STAGE === 'alpha' || STAGE === 'development') {
      logLevel.info(name)
      logLevel.log(snakeCaseParams)
    }
    karrotBridge.logEvent({
      analytics: {
        target: 'KARROT',
        name,
        params: JSON.stringify(snakeCaseParams),
      },
    })
  }
}
