import { z } from 'zod'

// TypeLatLng에 대한 Zod 스키마
const TypeLatLngSchema = z.object({
  latitude: z.number(),
  longitude: z.number(),
})

// V1PlaceType에 대한 Zod 스키마 (열거형 처리)
const UserLocationPlaceTypeSchema = z.enum([
  'PLACE_TYPE_UNSPECIFIED',
  'PLACE_TYPE_HOUSE',
  'PLACE_TYPE_OFFICE',
  'PLACE_TYPE_ETC',
])

// V1UserLocation에 대한 Zod 스키마
const V1UserLocationSchema = z.object({
  encryptedId: z.string(),
  point: TypeLatLngSchema,
  place: UserLocationPlaceTypeSchema,
})

// V1ListUserLocationsResponse에 대한 Zod 스키마
export const ZOD_SCHEMA_USER_LOCATION_TYPE = z.object({
  userLocations: z.array(V1UserLocationSchema),
  hasDisagreedTerms: z.boolean().optional(),
})

export type UserLocationType = z.infer<typeof ZOD_SCHEMA_USER_LOCATION_TYPE>
