/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const UserLocationPlaceType = {
    Unspecified: 'PLACE_TYPE_UNSPECIFIED',
    House: 'PLACE_TYPE_HOUSE',
    Office: 'PLACE_TYPE_OFFICE',
    Etc: 'PLACE_TYPE_ETC'
} as const;
export type UserLocationPlaceType = typeof UserLocationPlaceType[keyof typeof UserLocationPlaceType];


export function UserLocationPlaceTypeFromJSON(json: any): UserLocationPlaceType {
    return UserLocationPlaceTypeFromJSONTyped(json, false);
}

export function UserLocationPlaceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserLocationPlaceType {
    return json as UserLocationPlaceType;
}

export function UserLocationPlaceTypeToJSON(value?: UserLocationPlaceType | null): any {
    return value as any;
}

