import {
  scrollToTopPlugin,
  useScrollToTopEffect,
} from '@daangn/stackflow-plugin-scroll-to-top'

export function useIOSScrollToTopEffect(cb: () => void, deps?: any[]) {
  useScrollToTopEffect(() => {
    cb()
  }, [...(deps ?? [])])
}

export const iOSScrollToTopPlugin = scrollToTopPlugin
