import type { Plugin } from 'plantae'

const plantaeClientVersionPlugin = ({
  clientVersion,
}: {
  clientVersion: string
}): Plugin => {
  return {
    name: 'plugin-client-version',
    hooks: {
      beforeRequest: async (req) => {
        req.headers.set('x-local-map-client-version', clientVersion)

        return req
      },
    },
  }
}

export default plantaeClientVersionPlugin
