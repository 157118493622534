import { type StateCreator, create } from 'zustand'

import { funnelFromIdStore } from './funnelFromId'
import { queryFromIdStore } from './queryFromId'
import { queryIdStore } from './queryId'
import {
  screenDepthNameStore,
  type ScreenDepthNameTypes,
} from './screenDepthName'
import { searchFunnelIdStore } from './searchFunnelId'
import { type LocalMapSearchQueryFromIdType } from '../types/search'

type DerivedSearchLogParamStore = ReturnType<typeof funnelFromIdStore> &
  ReturnType<typeof queryFromIdStore> &
  ReturnType<typeof queryIdStore> &
  ReturnType<typeof searchFunnelIdStore> &
  ReturnType<typeof screenDepthNameStore>

type SearchLogParamStoreActions = {
  updateQueryFromId: (queryFromId?: LocalMapSearchQueryFromIdType) => void
  updateQueryId: () => void
  updateSearchFunnelId: () => void
  updateScreenDepthName: (name: ScreenDepthNameTypes) => void
}

type SearchLogParamStore = DerivedSearchLogParamStore & {
  actions: SearchLogParamStoreActions
}

const searchLogParamStoreActions: StateCreator<
  SearchLogParamStore,
  [],
  [],
  SearchLogParamStoreActions
> = (set, get, api) => ({
  updateQueryFromId: (queryFromId?: LocalMapSearchQueryFromIdType) => {
    queryFromIdStore(set, get, api).dispatchQueryFromId({
      type: 'update',
      payload: { queryFromId: queryFromId ?? 'unknown' },
    })
  },
  updateQueryId: () => {
    queryIdStore(set, get, api).dispatchQueryId({ type: 'auto-update' })
  },
  updateSearchFunnelId: () => {
    searchFunnelIdStore(set, get, api).dispatchSearchFunnelId({
      type: 'auto-update',
    })
  },
  updateScreenDepthName: (name: ScreenDepthNameTypes) => {
    screenDepthNameStore(set, get, api).updateScreenDepthName(name)
  },
})

export const useSearchLogParamStore = create<SearchLogParamStore>(
  (...args) => ({
    ...funnelFromIdStore(...args),
    ...queryFromIdStore(...args),
    ...queryIdStore(...args),
    ...searchFunnelIdStore(...args),
    ...screenDepthNameStore(...args),
    actions: searchLogParamStoreActions(...args),
  })
)

export const useSearchLogParamActions = () =>
  useSearchLogParamStore((state) => state.actions)
