/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { ExpertQuestSectionExpertService } from './ExpertQuestSectionExpertService';
import {
    ExpertQuestSectionExpertServiceFromJSON,
    ExpertQuestSectionExpertServiceFromJSONTyped,
    ExpertQuestSectionExpertServiceToJSON,
} from './ExpertQuestSectionExpertService';

/**
 * 
 * @export
 * @interface V1ExpertQuestSection
 */
export interface V1ExpertQuestSection {
    /**
     * 
     * @type {Array<ExpertQuestSectionExpertService>}
     * @memberof V1ExpertQuestSection
     */
    expertServices: Array<ExpertQuestSectionExpertService>;
    /**
     * 
     * @type {string}
     * @memberof V1ExpertQuestSection
     */
    viewMoreTargetUri: string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpertQuestSection
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof V1ExpertQuestSection
     */
    subTitle: string;
}

/**
 * Check if a given object implements the V1ExpertQuestSection interface.
 */
export function instanceOfV1ExpertQuestSection(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "expertServices" in value;
    isInstance = isInstance && "viewMoreTargetUri" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "subTitle" in value;

    return isInstance;
}

export function V1ExpertQuestSectionFromJSON(json: any): V1ExpertQuestSection {
    return V1ExpertQuestSectionFromJSONTyped(json, false);
}

export function V1ExpertQuestSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ExpertQuestSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'expertServices': ((json['expert_services'] as Array<any>).map(ExpertQuestSectionExpertServiceFromJSON)),
        'viewMoreTargetUri': json['view_more_target_uri'],
        'title': json['title'],
        'subTitle': json['sub_title'],
    };
}

export function V1ExpertQuestSectionToJSON(value?: V1ExpertQuestSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'expert_services': ((value.expertServices as Array<any>).map(ExpertQuestSectionExpertServiceToJSON)),
        'view_more_target_uri': value.viewMoreTargetUri,
        'title': value.title,
        'sub_title': value.subTitle,
    };
}

