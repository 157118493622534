import type { Plugin } from 'plantae'

const plantaeKarrotUserIdPlugin = ({ userId }: { userId: number }): Plugin => {
  return {
    name: 'plugin-karrot-user-id',
    hooks: {
      beforeRequest: async (req) => {
        req.headers.set('x-karrot-user-id', userId.toString())

        return req
      },
    },
  }
}

export default plantaeKarrotUserIdPlugin
