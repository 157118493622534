import * as Sentry from '@sentry/react'

/**
 * General한 케이스에서 사용하기 위해 만든 HOC에요.
 * 오류가 이곳에서 발생했다면, 예측하지 못한 오류가 발생한 상황이에요.
 * 유저에게 크리티컬한 경험을 제공한 케이스이기 때문에 sentry payload에 isCritical, isUnhandled 필드를 추가해요.
 * 해당 오류는 최우선으로 처리되어야 해요.
 *
 * @param Component
 * @param FallbackComponent
 * @param options
 * @returns
 */

export const withGeneralErrorBoundary = <P extends object>(
  Component: React.ComponentType<P>,
  FallbackComponent: React.ComponentType<{ error: Error }> = () => null,
  options?: { isHandled?: boolean; isCritical?: boolean }
) => {
  return (props: P) => {
    return (
      <Sentry.ErrorBoundary
        fallback={({ error }) => <FallbackComponent error={error} />}
        beforeCapture={(scope) => {
          if (!options?.isHandled) {
            scope.setExtra('isUnhandled', true)
          }

          if (options?.isCritical) {
            scope.setExtra('isCritical', true)
          }
        }}
      >
        <Component {...props} />
      </Sentry.ErrorBoundary>
    )
  }
}
