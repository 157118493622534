/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
/**
 * 
 * @export
 * @interface GetAddressByCoordinateResponseAddressRoad
 */
export interface GetAddressByCoordinateResponseAddressRoad {
    /**
     * 
     * @type {string}
     * @memberof GetAddressByCoordinateResponseAddressRoad
     */
    fullAddress: string;
    /**
     * 
     * @type {string}
     * @memberof GetAddressByCoordinateResponseAddressRoad
     */
    shortAddress: string;
    /**
     * 
     * @type {string}
     * @memberof GetAddressByCoordinateResponseAddressRoad
     */
    buildingName?: string;
}

/**
 * Check if a given object implements the GetAddressByCoordinateResponseAddressRoad interface.
 */
export function instanceOfGetAddressByCoordinateResponseAddressRoad(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fullAddress" in value;
    isInstance = isInstance && "shortAddress" in value;

    return isInstance;
}

export function GetAddressByCoordinateResponseAddressRoadFromJSON(json: any): GetAddressByCoordinateResponseAddressRoad {
    return GetAddressByCoordinateResponseAddressRoadFromJSONTyped(json, false);
}

export function GetAddressByCoordinateResponseAddressRoadFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAddressByCoordinateResponseAddressRoad {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fullAddress': json['full_address'],
        'shortAddress': json['short_address'],
        'buildingName': !exists(json, 'building_name') ? undefined : json['building_name'],
    };
}

export function GetAddressByCoordinateResponseAddressRoadToJSON(value?: GetAddressByCoordinateResponseAddressRoad | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'full_address': value.fullAddress,
        'short_address': value.shortAddress,
        'building_name': value.buildingName,
    };
}

