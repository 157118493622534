import { getSafeAreaValue } from '@src/local-map-utils/getSafeAreaValue'

import { dom } from './dom'
import { type Anchor } from './types'

/**
 * 적용된 position에 가장 가까운 anchor를 찾습니다.
 */
export function getNearestAnchor(
  position: number,
  positionerHeight: number,
  anchors: Anchor[]
): Anchor | undefined {
  if (anchors.length === 0) return undefined

  const anchorPositions = anchors.map((anchor) => {
    return getAnchorPosition(anchor, positionerHeight)
  })

  const nearestAnchorIndex = anchorPositions.reduce((prev, curr, index) => {
    const prevDiff = Math.abs(position - anchorPositions[prev])
    const currDiff = Math.abs(position - curr)
    if (prevDiff < currDiff) {
      return prev
    }
    return index
  }, 0)

  return anchors[nearestAnchorIndex]
}

/**
 * anchor의 position을 계산합니다.
 */
export function getAnchorPosition(
  anchor: Anchor,
  positionerHeight: number
): number {
  if (anchor.edge === 'top') {
    return anchor.offset + parseInt(getSafeAreaValue('top'), 10)
  }
  return positionerHeight - anchor.offset
}

/**
 * anchor의 sizingMode에 따라 position을 계산합니다.
 */
export function getAnchorSizingModePosition(
  anchor: Anchor,
  positionerId: string
) {
  return anchor.sizingMode === 'fixed'
    ? getAnchorPosition(anchor, dom.getPositionerHeight(positionerId))
    : Math.max(
        dom.getPositionerHeight(positionerId) -
          (dom.getContentScrollHeight(positionerId) +
            dom.getHandleHeight(positionerId)),
        0
      )
}
