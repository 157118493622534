/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PhoneCallPhoneCallType = {
    Unspecified: 'PHONE_CALL_TYPE_UNSPECIFIED',
    PhoneNumber: 'PHONE_CALL_TYPE_PHONE_NUMBER',
    SafeNumber: 'PHONE_CALL_TYPE_SAFE_NUMBER',
    CreateSafeNumberRequest: 'PHONE_CALL_TYPE_CREATE_SAFE_NUMBER_REQUEST'
} as const;
export type PhoneCallPhoneCallType = typeof PhoneCallPhoneCallType[keyof typeof PhoneCallPhoneCallType];


export function PhoneCallPhoneCallTypeFromJSON(json: any): PhoneCallPhoneCallType {
    return PhoneCallPhoneCallTypeFromJSONTyped(json, false);
}

export function PhoneCallPhoneCallTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhoneCallPhoneCallType {
    return json as PhoneCallPhoneCallType;
}

export function PhoneCallPhoneCallTypeToJSON(value?: PhoneCallPhoneCallType | null): any {
    return value as any;
}

