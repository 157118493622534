import { LocalStepKeys } from '@src/hooks/useStepRouter'
import { routeNames } from '@src/stackflow/routes'

/**
 * 전사 이벤트 네이밍 컨벤션을 따르는 이벤트 네임 구성요소 설명
 *
 * - platform: 이벤트가 발생한 플랫폼
 * - actionType: 이벤트를 발생 시킨 사용자 행동
 * - serviceName: 이벤트가 발생한 서비스
 * - screenName: 화면 이름
 * - areaName: 화면 내 영역 이름
 * - objectName: 화면 내 객체 이름
 * - extraInfo: 추가 정보
 */

export const platforms = ['client', 'server'] as const
export const actionType = ['clicked', 'shown', 'impressed'] as const
export const serviceNames = ['localMap'] as const
export const screenName = [...routeNames, ...LocalStepKeys] as const
export const version = ['v1', 'v2'] as const

export type Platform = (typeof platforms)[number]
export type ActionType = (typeof actionType)[number]
export type ServiceName = (typeof serviceNames)[number]
export type ScreenName = (typeof screenName)[number]
export type AreaName = string
export type ObjectName = string
export type Version = (typeof version)[number]

type ExtraStrings =
  | ''
  | `_${string}`
  | `_${string}_${string}`
  | `_${string}_${string}_${string}`
  | `_${string}_${string}_${string}_${string}`
  | `_${string}_${string}_${string}_${string}_${string}`

export type Required = {
  Platform: Platform
  ActionType: ActionType
  ServiceName: ServiceName
  ScreenName: ScreenName
  Version: Version
}

export type Optional = {
  ScreenName: `_${ScreenName}` | ''
  AreaName: `_${AreaName}` | ''
  ObjectName: `_${ObjectName}` | ''
}

// /**
//  * 전사 이벤트 네이밍 컨벤션을 따르는 이벤트 네임 타입
//  * @link 동네지도 이벤트 네이밍 정책 문서: https://www.notion.so/daangn/951406391220437785945f3d94dad00f?pvs=4
//  */
export type CorpEventName =
  `${Required['Platform']}_${Required['ActionType']}_${Required['ServiceName']}_${Required['ScreenName']}${Optional['AreaName']}${Optional['ObjectName']}${ExtraStrings}_${Required['Version']}`

/**
 * 정책에 위배되는 예외 이벤트 네임
 */
export const exceptionEventNames = [
  // 레거시 이벤트 네임으로 리소스상 전환하지 못함. -> 'client_searched_localMap' 으로 변경해야됨.
  // ref: https://daangn.slack.com/archives/C024B38T16F/p1729489500041709?thread_ts=1729225956.076519&cid=C024B38T16F
  'client_clicked_localMap_searchExecution_v1',
] as const
export type ExceptionEventName = (typeof exceptionEventNames)[number]

export type LocalMapEventName = CorpEventName | ExceptionEventName

export type LocalMapEventPartsName =
  `${Required['ServiceName']}_${Required['ScreenName']}${Optional['AreaName']}${Optional['ObjectName']}${ExtraStrings}`
