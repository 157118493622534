/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const V1PointItemViewType = {
    Unspecified: 'POINT_ITEM_VIEW_TYPE_UNSPECIFIED',
    LocalProfileReviewCommentary: 'POINT_ITEM_VIEW_TYPE_LOCAL_PROFILE_REVIEW_COMMENTARY'
} as const;
export type V1PointItemViewType = typeof V1PointItemViewType[keyof typeof V1PointItemViewType];


export function V1PointItemViewTypeFromJSON(json: any): V1PointItemViewType {
    return V1PointItemViewTypeFromJSONTyped(json, false);
}

export function V1PointItemViewTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1PointItemViewType {
    return json as V1PointItemViewType;
}

export function V1PointItemViewTypeToJSON(value?: V1PointItemViewType | null): any {
    return value as any;
}

