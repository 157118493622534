import {
  type RumInitConfiguration,
  datadogRum,
} from '@datadog/browser-rum-slim'

export const InitdatadogRum = (config: RumInitConfiguration) => {
  datadogRum.init(config)
}

export const setDatadogRumUserConfig: typeof datadogRum.setUser = (user) => {
  datadogRum.setUser(user)
}
