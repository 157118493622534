/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { TypeLatLng } from './TypeLatLng';
import {
    TypeLatLngFromJSON,
    TypeLatLngFromJSONTyped,
    TypeLatLngToJSON,
} from './TypeLatLng';
import type { V1MapBounds } from './V1MapBounds';
import {
    V1MapBoundsFromJSON,
    V1MapBoundsFromJSONTyped,
    V1MapBoundsToJSON,
} from './V1MapBounds';
import type { V1SearchEventClientData } from './V1SearchEventClientData';
import {
    V1SearchEventClientDataFromJSON,
    V1SearchEventClientDataFromJSONTyped,
    V1SearchEventClientDataToJSON,
} from './V1SearchEventClientData';

/**
 * 
 * @export
 * @interface V1SearchLocalProfilesRequest
 */
export interface V1SearchLocalProfilesRequest {
    /**
     * 
     * @type {string}
     * @memberof V1SearchLocalProfilesRequest
     */
    query: string;
    /**
     * 
     * @type {number}
     * @memberof V1SearchLocalProfilesRequest
     */
    pageSize: number;
    /**
     * 
     * @type {string}
     * @memberof V1SearchLocalProfilesRequest
     */
    nextPageToken?: string;
    /**
     * 
     * @type {TypeLatLng}
     * @memberof V1SearchLocalProfilesRequest
     */
    coordinatesForSearch: TypeLatLng;
    /**
     * 
     * @type {TypeLatLng}
     * @memberof V1SearchLocalProfilesRequest
     */
    coordinatesForDistance?: TypeLatLng;
    /**
     * 
     * @type {V1MapBounds}
     * @memberof V1SearchLocalProfilesRequest
     */
    boundsForSearch?: V1MapBounds;
    /**
     * 
     * @type {V1SearchEventClientData}
     * @memberof V1SearchLocalProfilesRequest
     */
    searchEventClientData: V1SearchEventClientData;
    /**
     * 
     * @type {V1MapBounds}
     * @memberof V1SearchLocalProfilesRequest
     */
    userViewBounds: V1MapBounds;
}

/**
 * Check if a given object implements the V1SearchLocalProfilesRequest interface.
 */
export function instanceOfV1SearchLocalProfilesRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "query" in value;
    isInstance = isInstance && "pageSize" in value;
    isInstance = isInstance && "coordinatesForSearch" in value;
    isInstance = isInstance && "searchEventClientData" in value;
    isInstance = isInstance && "userViewBounds" in value;

    return isInstance;
}

export function V1SearchLocalProfilesRequestFromJSON(json: any): V1SearchLocalProfilesRequest {
    return V1SearchLocalProfilesRequestFromJSONTyped(json, false);
}

export function V1SearchLocalProfilesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1SearchLocalProfilesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'query': json['query'],
        'pageSize': json['page_size'],
        'nextPageToken': !exists(json, 'next_page_token') ? undefined : json['next_page_token'],
        'coordinatesForSearch': TypeLatLngFromJSON(json['coordinates_for_search']),
        'coordinatesForDistance': !exists(json, 'coordinates_for_distance') ? undefined : TypeLatLngFromJSON(json['coordinates_for_distance']),
        'boundsForSearch': !exists(json, 'bounds_for_search') ? undefined : V1MapBoundsFromJSON(json['bounds_for_search']),
        'searchEventClientData': V1SearchEventClientDataFromJSON(json['search_event_client_data']),
        'userViewBounds': V1MapBoundsFromJSON(json['user_view_bounds']),
    };
}

export function V1SearchLocalProfilesRequestToJSON(value?: V1SearchLocalProfilesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'query': value.query,
        'page_size': value.pageSize,
        'next_page_token': value.nextPageToken,
        'coordinates_for_search': TypeLatLngToJSON(value.coordinatesForSearch),
        'coordinates_for_distance': TypeLatLngToJSON(value.coordinatesForDistance),
        'bounds_for_search': V1MapBoundsToJSON(value.boundsForSearch),
        'search_event_client_data': V1SearchEventClientDataToJSON(value.searchEventClientData),
        'user_view_bounds': V1MapBoundsToJSON(value.userViewBounds),
    };
}

