import { forwardRef, type PropsWithChildren } from 'react'

import * as css from './SectionContainer.css'

interface SectionContainerProps {
  [key: string]: any
}

const SectionContainer = forwardRef<
  HTMLDivElement,
  PropsWithChildren<SectionContainerProps>
>(({ children, ...props }, ref) => {
  return (
    <section className={css.container} ref={ref} {...props}>
      {children}
    </section>
  )
})

export default SectionContainer
