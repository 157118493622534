/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const V1SearchAutoCompleteViewType = {
    Unspecified: 'SEARCH_AUTO_COMPLETE_VIEW_TYPE_UNSPECIFIED',
    Keyword: 'SEARCH_AUTO_COMPLETE_VIEW_TYPE_KEYWORD',
    LocalProfile: 'SEARCH_AUTO_COMPLETE_VIEW_TYPE_LOCAL_PROFILE'
} as const;
export type V1SearchAutoCompleteViewType = typeof V1SearchAutoCompleteViewType[keyof typeof V1SearchAutoCompleteViewType];


export function V1SearchAutoCompleteViewTypeFromJSON(json: any): V1SearchAutoCompleteViewType {
    return V1SearchAutoCompleteViewTypeFromJSONTyped(json, false);
}

export function V1SearchAutoCompleteViewTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1SearchAutoCompleteViewType {
    return json as V1SearchAutoCompleteViewType;
}

export function V1SearchAutoCompleteViewTypeToJSON(value?: V1SearchAutoCompleteViewType | null): any {
    return value as any;
}

