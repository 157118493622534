import '../../../react-utils/bottom-sheet/bottomSheet.css'

import { useMemo } from 'react'

import { type LocalMapStepKeys } from '@src/hooks/useStepRouter'
import { getAnchorPosition } from '@src/react-utils/bottom-sheet/utils'
import { Loading } from '@src/react-utils/loading/Loading'

import {
  getBottomSheetAnchorById,
  getBottomSheetInitialAnchorId,
  getMiddleOffset,
} from './anchor'
import { checkHeightViewType } from './useLandscapeAnchors'

interface LocalMapBottomSheetSkeletonProps {
  stepType?: LocalMapStepKeys
  children?: React.ReactNode
}

const LocalMapBottomSheetSkeleton = ({
  stepType = 'home',
  children,
}: LocalMapBottomSheetSkeletonProps) => {
  const landscapeType = checkHeightViewType()
  const bottomSheetInitialAnchorId =
    getBottomSheetInitialAnchorId(landscapeType)
  const bottomSheetAnchor = getBottomSheetAnchorById({
    anchorId: bottomSheetInitialAnchorId,
    heightViewType: landscapeType,
    stepType,
  })

  const anchorPosition = useMemo(() => {
    const defaultAnchorPosition = getMiddleOffset()

    if (!bottomSheetAnchor) {
      return defaultAnchorPosition
    }

    return getAnchorPosition(bottomSheetAnchor, window.innerHeight)
  }, [bottomSheetAnchor])

  const withHandle = stepType === 'home'

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        userSelect: 'none',
        touchAction: 'none',
        transform: `translateY(${anchorPosition}px)`,
        height: `calc(100vh - ${anchorPosition}px)`,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'var(--seed-semantic-color-paper-default)',
        borderRadius: '20px 20px 0 0',
        boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.1)',
      }}
    >
      {withHandle && (
        <div className="seed-bottom-sheet" data-part="handle">
          <div className="seed-bottom-sheet" data-part="handlebar" />
        </div>
      )}
      {children ?? <Loading />}
    </div>
  )
}

export default LocalMapBottomSheetSkeleton
