/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ThemeBusinessPostSectionThemeType = {
    Unspecified: 'THEME_TYPE_UNSPECIFIED',
    Food: 'THEME_TYPE_FOOD',
    Beauty: 'THEME_TYPE_BEAUTY',
    Health: 'THEME_TYPE_HEALTH',
    Education: 'THEME_TYPE_EDUCATION',
    Life: 'THEME_TYPE_LIFE',
    All: 'THEME_TYPE_ALL'
} as const;
export type ThemeBusinessPostSectionThemeType = typeof ThemeBusinessPostSectionThemeType[keyof typeof ThemeBusinessPostSectionThemeType];


export function ThemeBusinessPostSectionThemeTypeFromJSON(json: any): ThemeBusinessPostSectionThemeType {
    return ThemeBusinessPostSectionThemeTypeFromJSONTyped(json, false);
}

export function ThemeBusinessPostSectionThemeTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ThemeBusinessPostSectionThemeType {
    return json as ThemeBusinessPostSectionThemeType;
}

export function ThemeBusinessPostSectionThemeTypeToJSON(value?: ThemeBusinessPostSectionThemeType | null): any {
    return value as any;
}

