import type { Plugin } from 'plantae'

const plantaeAppVersionPlugin = ({
  appVersion,
}: {
  appVersion: string
}): Plugin => {
  return {
    name: 'plugin-app-version',
    hooks: {
      beforeRequest: async (req) => {
        req.headers.set('x-app-version', appVersion)

        return req
      },
    },
  }
}

export default plantaeAppVersionPlugin
