import * as css from './SectionRecommendPost.css'
import SectionRecommendPostItemSkeleton from './SectionRecommendPostItemSkeleton'
import SectionSkeleton from '../lib/section/SectionSkeleton'

const SectionRecommendPostSkeleton = () => {
  return (
    <SectionSkeleton
      sectionTitleProps={{
        isRightButton: false,
      }}
    >
      <div className={css.pageItem}>
        <SectionRecommendPostItemSkeleton />
        <SectionRecommendPostItemSkeleton />
        <SectionRecommendPostItemSkeleton />
      </div>
    </SectionSkeleton>
  )
}

export default SectionRecommendPostSkeleton
