/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1AddressAutoComplete } from './V1AddressAutoComplete';
import {
    V1AddressAutoCompleteFromJSON,
    V1AddressAutoCompleteFromJSONTyped,
    V1AddressAutoCompleteToJSON,
} from './V1AddressAutoComplete';

/**
 * 
 * @export
 * @interface V1AddressAutoCompleteResponse
 */
export interface V1AddressAutoCompleteResponse {
    /**
     * 
     * @type {Array<V1AddressAutoComplete>}
     * @memberof V1AddressAutoCompleteResponse
     */
    results: Array<V1AddressAutoComplete>;
    /**
     * 
     * @type {string}
     * @memberof V1AddressAutoCompleteResponse
     */
    nextPageToken?: string;
}

/**
 * Check if a given object implements the V1AddressAutoCompleteResponse interface.
 */
export function instanceOfV1AddressAutoCompleteResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function V1AddressAutoCompleteResponseFromJSON(json: any): V1AddressAutoCompleteResponse {
    return V1AddressAutoCompleteResponseFromJSONTyped(json, false);
}

export function V1AddressAutoCompleteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1AddressAutoCompleteResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(V1AddressAutoCompleteFromJSON)),
        'nextPageToken': !exists(json, 'next_page_token') ? undefined : json['next_page_token'],
    };
}

export function V1AddressAutoCompleteResponseToJSON(value?: V1AddressAutoCompleteResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(V1AddressAutoCompleteToJSON)),
        'next_page_token': value.nextPageToken,
    };
}

