/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
/**
 * 
 * @export
 * @interface V1SearchEventClientData
 */
export interface V1SearchEventClientData {
    /**
     * 
     * @type {string}
     * @memberof V1SearchEventClientData
     */
    queryId: string;
    /**
     * 
     * @type {string}
     * @memberof V1SearchEventClientData
     */
    funnelFrom: string;
    /**
     * 
     * @type {string}
     * @memberof V1SearchEventClientData
     */
    queryFrom: string;
    /**
     * 
     * @type {string}
     * @memberof V1SearchEventClientData
     */
    screenDepthName: string;
    /**
     * 
     * @type {string}
     * @memberof V1SearchEventClientData
     */
    searchFunnelId: string;
}

/**
 * Check if a given object implements the V1SearchEventClientData interface.
 */
export function instanceOfV1SearchEventClientData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "queryId" in value;
    isInstance = isInstance && "funnelFrom" in value;
    isInstance = isInstance && "queryFrom" in value;
    isInstance = isInstance && "screenDepthName" in value;
    isInstance = isInstance && "searchFunnelId" in value;

    return isInstance;
}

export function V1SearchEventClientDataFromJSON(json: any): V1SearchEventClientData {
    return V1SearchEventClientDataFromJSONTyped(json, false);
}

export function V1SearchEventClientDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1SearchEventClientData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'queryId': json['query_id'],
        'funnelFrom': json['funnel_from'],
        'queryFrom': json['query_from'],
        'screenDepthName': json['screen_depth_name'],
        'searchFunnelId': json['search_funnel_id'],
    };
}

export function V1SearchEventClientDataToJSON(value?: V1SearchEventClientData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'query_id': value.queryId,
        'funnel_from': value.funnelFrom,
        'query_from': value.queryFrom,
        'screen_depth_name': value.screenDepthName,
        'search_funnel_id': value.searchFunnelId,
    };
}

