/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
/**
 * 
 * @export
 * @interface V1SearchResultShortcut
 */
export interface V1SearchResultShortcut {
    /**
     * 
     * @type {string}
     * @memberof V1SearchResultShortcut
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof V1SearchResultShortcut
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof V1SearchResultShortcut
     */
    subTitle: string;
    /**
     * 
     * @type {string}
     * @memberof V1SearchResultShortcut
     */
    targetUri: string;
    /**
     * 
     * @type {string}
     * @memberof V1SearchResultShortcut
     */
    iconImageUrl: string;
}

/**
 * Check if a given object implements the V1SearchResultShortcut interface.
 */
export function instanceOfV1SearchResultShortcut(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "subTitle" in value;
    isInstance = isInstance && "targetUri" in value;
    isInstance = isInstance && "iconImageUrl" in value;

    return isInstance;
}

export function V1SearchResultShortcutFromJSON(json: any): V1SearchResultShortcut {
    return V1SearchResultShortcutFromJSONTyped(json, false);
}

export function V1SearchResultShortcutFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1SearchResultShortcut {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'subTitle': json['sub_title'],
        'targetUri': json['target_uri'],
        'iconImageUrl': json['icon_image_url'],
    };
}

export function V1SearchResultShortcutToJSON(value?: V1SearchResultShortcut | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'sub_title': value.subTitle,
        'target_uri': value.targetUri,
        'icon_image_url': value.iconImageUrl,
    };
}

