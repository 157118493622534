/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1MapPinItem } from './V1MapPinItem';
import {
    V1MapPinItemFromJSON,
    V1MapPinItemFromJSONTyped,
    V1MapPinItemToJSON,
} from './V1MapPinItem';

/**
 * 
 * @export
 * @interface V1ListCommentaryMapPinItemsResponse
 */
export interface V1ListCommentaryMapPinItemsResponse {
    /**
     * 
     * @type {Array<V1MapPinItem>}
     * @memberof V1ListCommentaryMapPinItemsResponse
     */
    mapPinItems: Array<V1MapPinItem>;
}

/**
 * Check if a given object implements the V1ListCommentaryMapPinItemsResponse interface.
 */
export function instanceOfV1ListCommentaryMapPinItemsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "mapPinItems" in value;

    return isInstance;
}

export function V1ListCommentaryMapPinItemsResponseFromJSON(json: any): V1ListCommentaryMapPinItemsResponse {
    return V1ListCommentaryMapPinItemsResponseFromJSONTyped(json, false);
}

export function V1ListCommentaryMapPinItemsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ListCommentaryMapPinItemsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mapPinItems': ((json['map_pin_items'] as Array<any>).map(V1MapPinItemFromJSON)),
    };
}

export function V1ListCommentaryMapPinItemsResponseToJSON(value?: V1ListCommentaryMapPinItemsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'map_pin_items': ((value.mapPinItems as Array<any>).map(V1MapPinItemToJSON)),
    };
}

