import * as Sentry from '@sentry/react'
import pRetry from 'p-retry'
import pTimeout from 'p-timeout'

import BridgeTimeoutError from '@src/error/BridgeTimeoutError'

import { karrotBridge } from '../bridge'
import {
  type BridgeAppInfo,
  DEFAULT_APP,
  DEFAULT_GEOLOCATION,
  DEFAULT_REGION,
  DEFAULT_USER,
  type BridgeRegionInfo,
  type BridgeUserInfo,
} from '../constants'

// The number of milliseconds before starting the first retry. from p-retry
const BRIDGE_RETRY_DELAY = 100
const BRIDGE_TIMEOUT_MS = 250
const SHORT_BRIDGE_TIMEOUT_MS = 50

const environment = karrotBridge.driver.getCurrentEnvironment()

export const clientEnvironment = {
  isIOS: 'Cupertino' === environment,
  isAndroid: 'Android' === environment,
  isWeb: 'Web' === environment,
  platform: environment,
}
export type ClientEnvironmentType = typeof clientEnvironment

export const executeByEnvironment = ({
  onApp,
  onAndroid,
  onIos,
  onWeb,
}: {
  onApp?: () => void
  onAndroid?: () => void
  onIos?: () => void
  onWeb?: () => void
}) => {
  switch (clientEnvironment.platform) {
    case 'Android':
      onAndroid?.()
      onApp?.()
      break
    case 'Cupertino':
      onIos?.()
      onApp?.()
      break
    case 'Web':
      onWeb?.()
      break
  }
}

export async function getKarrotSessionInfo() {
  if (clientEnvironment.isWeb) {
    return Promise.resolve(null)
  }

  try {
    const sessionInfo = await pRetry(
      () =>
        pTimeout(karrotBridge.getKarrotAnalyticsSessionInfo({}), {
          milliseconds: SHORT_BRIDGE_TIMEOUT_MS,
          message: new BridgeTimeoutError(
            'karrotBridge.getKarrotAnalyticsSessionInfo({}) timeout'
          ),
        }),
      {
        retries: 1,
        minTimeout: 16,
      }
    )
    return sessionInfo.info.karrotAnalyticsSession
  } catch {
    return Promise.resolve(null)
  }
}
export async function getApp(): Promise<BridgeAppInfo> {
  if (DEFAULT_APP) {
    return DEFAULT_APP
  }
  const app = await pRetry(
    () =>
      pTimeout(karrotBridge.getAppInfo({}), {
        milliseconds: BRIDGE_TIMEOUT_MS,
        message: new BridgeTimeoutError('karrotBridge.getAppInfo({}) timeout'),
      }),
    {
      retries: 3,
      minTimeout: BRIDGE_RETRY_DELAY,
    }
  )

  return app.info.app ?? undefined
}
export async function getRegion(): Promise<BridgeRegionInfo> {
  if (DEFAULT_REGION) {
    return DEFAULT_REGION as BridgeRegionInfo
  }

  const region = await pRetry(
    () =>
      pTimeout(karrotBridge.getRegionInfo({}), {
        milliseconds: BRIDGE_TIMEOUT_MS,
        message: new BridgeTimeoutError(
          'karrotBridge.getRegionInfo({}) timeout'
        ),
      }),
    {
      retries: 3,
      minTimeout: BRIDGE_RETRY_DELAY,
    }
  )

  return region.info.region ?? undefined
}
export async function getUser(): Promise<BridgeUserInfo> {
  if (DEFAULT_USER) {
    return DEFAULT_USER as BridgeUserInfo
  }

  const user = await pRetry(
    () =>
      pTimeout(karrotBridge.getUserInfo({}), {
        milliseconds: BRIDGE_TIMEOUT_MS,
        message: new BridgeTimeoutError('karrotBridge.getUserInfo({}) timeout'),
      }),
    {
      retries: 3,
      minTimeout: BRIDGE_RETRY_DELAY,
    }
  )

  return user.info.user ?? undefined
}

export async function getGeolocation({
  timeout = BRIDGE_TIMEOUT_MS,
  systemSettingPopupEnabled = true,
}: {
  timeout?: number
  systemSettingPopupEnabled?: boolean
}) {
  if (DEFAULT_GEOLOCATION) {
    return DEFAULT_GEOLOCATION
  }

  try {
    const resp = await pRetry(
      () => {
        return pTimeout(
          karrotBridge.getCurrentPosition({
            systemSettingPopupEnabled,
          }),
          {
            milliseconds: timeout,
            message: new BridgeTimeoutError(
              'karrotBridge.getCurrentPosition({}) timeout'
            ),
          }
        )
      },
      {
        retries: 10,
        minTimeout: BRIDGE_RETRY_DELAY,
        maxRetryTime: 3000,
      }
    )

    return resp.geolocation
  } catch (e) {
    Sentry.captureException(e)

    return Promise.resolve(null)
  }
}
