/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1ExperimentSegment } from './V1ExperimentSegment';
import {
    V1ExperimentSegmentFromJSON,
    V1ExperimentSegmentFromJSONTyped,
    V1ExperimentSegmentToJSON,
} from './V1ExperimentSegment';

/**
 * 
 * @export
 * @interface V1GetExperimentSegmentsResponse
 */
export interface V1GetExperimentSegmentsResponse {
    /**
     * 
     * @type {Array<V1ExperimentSegment>}
     * @memberof V1GetExperimentSegmentsResponse
     */
    experimentSegments: Array<V1ExperimentSegment>;
}

/**
 * Check if a given object implements the V1GetExperimentSegmentsResponse interface.
 */
export function instanceOfV1GetExperimentSegmentsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "experimentSegments" in value;

    return isInstance;
}

export function V1GetExperimentSegmentsResponseFromJSON(json: any): V1GetExperimentSegmentsResponse {
    return V1GetExperimentSegmentsResponseFromJSONTyped(json, false);
}

export function V1GetExperimentSegmentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1GetExperimentSegmentsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'experimentSegments': ((json['experiment_segments'] as Array<any>).map(V1ExperimentSegmentFromJSON)),
    };
}

export function V1GetExperimentSegmentsResponseToJSON(value?: V1GetExperimentSegmentsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'experiment_segments': ((value.experimentSegments as Array<any>).map(V1ExperimentSegmentToJSON)),
    };
}

