/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SearchResultLocalProfileOperationStatus = {
    Unspecified: 'OPERATION_STATUS_UNSPECIFIED',
    Open: 'OPERATION_STATUS_OPEN',
    ClosedForPreparation: 'OPERATION_STATUS_CLOSED_FOR_PREPARATION',
    BreakTime: 'OPERATION_STATUS_BREAK_TIME',
    ClosedToday: 'OPERATION_STATUS_CLOSED_TODAY',
    Open24Hours: 'OPERATION_STATUS_OPEN_24_HOURS'
} as const;
export type SearchResultLocalProfileOperationStatus = typeof SearchResultLocalProfileOperationStatus[keyof typeof SearchResultLocalProfileOperationStatus];


export function SearchResultLocalProfileOperationStatusFromJSON(json: any): SearchResultLocalProfileOperationStatus {
    return SearchResultLocalProfileOperationStatusFromJSONTyped(json, false);
}

export function SearchResultLocalProfileOperationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchResultLocalProfileOperationStatus {
    return json as SearchResultLocalProfileOperationStatus;
}

export function SearchResultLocalProfileOperationStatusToJSON(value?: SearchResultLocalProfileOperationStatus | null): any {
    return value as any;
}

