import { createFetch } from 'plantae'

import { type BridgeInfoType } from '@src/bridge/context/BridgeInfoProvider'
import { CLIENT_VERSION } from '@src/constants'
import plantaeAppVersionPlugin from '@src/plantae/plugins/plantaeAppVersionPlugin'
import plantaeAuthPlugin from '@src/plantae/plugins/plantaeAuthPlugin'
import plantaeCommonHeadersPlugin from '@src/plantae/plugins/plantaeCommonHeadersPlugin'
import plantaeCountryCodePlugin from '@src/plantae/plugins/plantaeCountryCodePlugin'
import plantaeDeviceIdentityPlugin from '@src/plantae/plugins/plantaeDeviceIdentityPlugin'
import plantaeKarrotRegionIdPlugin from '@src/plantae/plugins/plantaeKarrotRegionIdPlugin'
import plantaeKarrotSessionIdPlugin from '@src/plantae/plugins/plantaeKarrotSessionIdPlugin'
import plantaeKarrotUserIdPlugin from '@src/plantae/plugins/plantaeKarrotUserIdPlugin'
import plantaeRequestIdPlugin from '@src/plantae/plugins/plantaeRequestIdPlugin'
import plantaeUserAgentPlugin from '@src/plantae/plugins/plantaeUserAgentPlugin'

import plantaeClientVersionPlugin from './plugins/plantaeClientVersioPlugin'

export const makePlantaeFetch = ({ app, user, region }: BridgeInfoType) =>
  createFetch({
    client: fetch,
    plugins: [
      plantaeAuthPlugin({
        fallbackAuthToken: user.authToken,
      }),
      plantaeKarrotUserIdPlugin({
        userId: user.id,
      }),
      plantaeDeviceIdentityPlugin({
        deviceIdentity: app.deviceIdentity,
      }),
      plantaeUserAgentPlugin({
        userAgent: app.userAgent,
      }),
      plantaeKarrotRegionIdPlugin({
        regionId: region.id,
      }),
      plantaeAppVersionPlugin({
        appVersion: app.appVersion,
      }),
      plantaeCountryCodePlugin({
        countryCode: app.country,
      }),
      plantaeKarrotSessionIdPlugin(),
      plantaeRequestIdPlugin(),
      plantaeCommonHeadersPlugin(), // iOS, Android (>=23.46.0 이상)
      plantaeClientVersionPlugin({
        clientVersion: CLIENT_VERSION,
      }),
    ],
  })
