/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { SearchCategoryKeywordsSuggestionSuggestion } from './SearchCategoryKeywordsSuggestionSuggestion';
import {
    SearchCategoryKeywordsSuggestionSuggestionFromJSON,
    SearchCategoryKeywordsSuggestionSuggestionFromJSONTyped,
    SearchCategoryKeywordsSuggestionSuggestionToJSON,
} from './SearchCategoryKeywordsSuggestionSuggestion';

/**
 * 
 * @export
 * @interface V1SearchCategoryKeywordsSuggestion
 */
export interface V1SearchCategoryKeywordsSuggestion {
    /**
     * 
     * @type {string}
     * @memberof V1SearchCategoryKeywordsSuggestion
     */
    referrerQuery: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof V1SearchCategoryKeywordsSuggestion
     */
    keywords: Array<string>;
    /**
     * 
     * @type {Array<SearchCategoryKeywordsSuggestionSuggestion>}
     * @memberof V1SearchCategoryKeywordsSuggestion
     */
    suggestions: Array<SearchCategoryKeywordsSuggestionSuggestion>;
}

/**
 * Check if a given object implements the V1SearchCategoryKeywordsSuggestion interface.
 */
export function instanceOfV1SearchCategoryKeywordsSuggestion(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "referrerQuery" in value;
    isInstance = isInstance && "keywords" in value;
    isInstance = isInstance && "suggestions" in value;

    return isInstance;
}

export function V1SearchCategoryKeywordsSuggestionFromJSON(json: any): V1SearchCategoryKeywordsSuggestion {
    return V1SearchCategoryKeywordsSuggestionFromJSONTyped(json, false);
}

export function V1SearchCategoryKeywordsSuggestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1SearchCategoryKeywordsSuggestion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'referrerQuery': json['referrer_query'],
        'keywords': json['keywords'],
        'suggestions': ((json['suggestions'] as Array<any>).map(SearchCategoryKeywordsSuggestionSuggestionFromJSON)),
    };
}

export function V1SearchCategoryKeywordsSuggestionToJSON(value?: V1SearchCategoryKeywordsSuggestion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'referrer_query': value.referrerQuery,
        'keywords': value.keywords,
        'suggestions': ((value.suggestions as Array<any>).map(SearchCategoryKeywordsSuggestionSuggestionToJSON)),
    };
}

