import { HOME_SEARCH_INPUT_HEIGHT_PX } from '@src/pages/home-activity/_constants/size'
import { Skeleton } from '@src/react-utils/skeleton'

import * as css from './HomeSearchInput.css'

const HomeSearchInputSkeleton = () => {
  return (
    <div className={css.container}>
      <Skeleton width="100%" height={`${HOME_SEARCH_INPUT_HEIGHT_PX}px`} />
    </div>
  )
}

export default HomeSearchInputSkeleton
