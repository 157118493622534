/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
/**
 * 
 * @export
 * @interface SocialCapitalRanking
 */
export interface SocialCapitalRanking {
    /**
     * 
     * @type {number}
     * @memberof SocialCapitalRanking
     */
    rank: number;
    /**
     * 
     * @type {number}
     * @memberof SocialCapitalRanking
     */
    point: number;
    /**
     * 
     * @type {number}
     * @memberof SocialCapitalRanking
     */
    rankChange: number;
}

/**
 * Check if a given object implements the SocialCapitalRanking interface.
 */
export function instanceOfSocialCapitalRanking(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "rank" in value;
    isInstance = isInstance && "point" in value;
    isInstance = isInstance && "rankChange" in value;

    return isInstance;
}

export function SocialCapitalRankingFromJSON(json: any): SocialCapitalRanking {
    return SocialCapitalRankingFromJSONTyped(json, false);
}

export function SocialCapitalRankingFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocialCapitalRanking {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rank': json['rank'],
        'point': json['point'],
        'rankChange': json['rank_change'],
    };
}

export function SocialCapitalRankingToJSON(value?: SocialCapitalRanking | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rank': value.rank,
        'point': value.point,
        'rank_change': value.rankChange,
    };
}

