import type { Plugin } from 'plantae'

const plantaeCountryCodePlugin = ({
  countryCode,
}: {
  countryCode: string
}): Plugin => {
  return {
    name: 'plugin-country-code',
    hooks: {
      beforeRequest: async (req) => {
        req.headers.set('x-country-code', countryCode)

        return req
      },
    },
  }
}

export default plantaeCountryCodePlugin
