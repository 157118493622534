import { z } from 'zod'

const KarrotErrorDataSchema = z.object({
  status: z.object({
    code: z.string(),
    message: z.string(),
    type: z.string(),
  }),
})

export type KarrotErrorData = z.infer<typeof KarrotErrorDataSchema>

export const checkKarrotError = (errorData: unknown) => {
  const parsedData = KarrotErrorDataSchema.safeParse(errorData)

  if (parsedData.success) {
    return {
      karrotErrorData: parsedData.data.status,
    }
  }

  return {
    karrotErrorData: null,
  }
}
