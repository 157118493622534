/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1LocalProfileReviewCommentary } from './V1LocalProfileReviewCommentary';
import {
    V1LocalProfileReviewCommentaryFromJSON,
    V1LocalProfileReviewCommentaryFromJSONTyped,
    V1LocalProfileReviewCommentaryToJSON,
} from './V1LocalProfileReviewCommentary';
import type { V1PointItemViewType } from './V1PointItemViewType';
import {
    V1PointItemViewTypeFromJSON,
    V1PointItemViewTypeFromJSONTyped,
    V1PointItemViewTypeToJSON,
} from './V1PointItemViewType';

/**
 * 
 * @export
 * @interface V1MapPinItem
 */
export interface V1MapPinItem {
    /**
     * 
     * @type {V1PointItemViewType}
     * @memberof V1MapPinItem
     */
    pointItemViewType: V1PointItemViewType;
    /**
     * 
     * @type {V1LocalProfileReviewCommentary}
     * @memberof V1MapPinItem
     */
    localProfileReviewCommentary?: V1LocalProfileReviewCommentary;
}

/**
 * Check if a given object implements the V1MapPinItem interface.
 */
export function instanceOfV1MapPinItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "pointItemViewType" in value;

    return isInstance;
}

export function V1MapPinItemFromJSON(json: any): V1MapPinItem {
    return V1MapPinItemFromJSONTyped(json, false);
}

export function V1MapPinItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1MapPinItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pointItemViewType': V1PointItemViewTypeFromJSON(json['point_item_view_type']),
        'localProfileReviewCommentary': !exists(json, 'local_profile_review_commentary') ? undefined : V1LocalProfileReviewCommentaryFromJSON(json['local_profile_review_commentary']),
    };
}

export function V1MapPinItemToJSON(value?: V1MapPinItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'point_item_view_type': V1PointItemViewTypeToJSON(value.pointItemViewType),
        'local_profile_review_commentary': V1LocalProfileReviewCommentaryToJSON(value.localProfileReviewCommentary),
    };
}

