import { type StateCreator } from 'zustand'

import { type LocalMapSearchFunnelFromIdType } from '../types/search'

interface FunnelFromIdStore {
  funnelFromId: LocalMapSearchFunnelFromIdType
  dispatchFunnelFromId: (action: FunnelFromIdAction) => void
}

export type FunnelFromIdState = {
  funnelFromId: LocalMapSearchFunnelFromIdType
}

export type FunnelFromIdAction = { type: 'update'; payload: FunnelFromIdState }

export const funnelFromIdReducer = (
  state: FunnelFromIdState,
  action: FunnelFromIdAction
) => {
  switch (action.type) {
    case 'update': {
      return { ...state, funnelFromId: action.payload.funnelFromId }
    }
    default:
      throw new Error('Given action type does not exist.')
  }
}

export const funnelFromIdStore: StateCreator<FunnelFromIdStore> = (set) => ({
  funnelFromId: 'local_map' as LocalMapSearchFunnelFromIdType,
  dispatchFunnelFromId: (action: FunnelFromIdAction) =>
    set((state) => funnelFromIdReducer(state, action)),
})
