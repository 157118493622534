/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { PhoneCallCreateSafeNumberRequest } from './PhoneCallCreateSafeNumberRequest';
import {
    PhoneCallCreateSafeNumberRequestFromJSON,
    PhoneCallCreateSafeNumberRequestFromJSONTyped,
    PhoneCallCreateSafeNumberRequestToJSON,
} from './PhoneCallCreateSafeNumberRequest';
import type { PhoneCallPhoneCallType } from './PhoneCallPhoneCallType';
import {
    PhoneCallPhoneCallTypeFromJSON,
    PhoneCallPhoneCallTypeFromJSONTyped,
    PhoneCallPhoneCallTypeToJSON,
} from './PhoneCallPhoneCallType';

/**
 * 
 * @export
 * @interface LocalProfilePreviewPhoneCall
 */
export interface LocalProfilePreviewPhoneCall {
    /**
     * 
     * @type {string}
     * @memberof LocalProfilePreviewPhoneCall
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof LocalProfilePreviewPhoneCall
     */
    safeNumber?: string;
    /**
     * 
     * @type {PhoneCallCreateSafeNumberRequest}
     * @memberof LocalProfilePreviewPhoneCall
     */
    createSafeNumberRequest?: PhoneCallCreateSafeNumberRequest;
    /**
     * 
     * @type {PhoneCallPhoneCallType}
     * @memberof LocalProfilePreviewPhoneCall
     */
    phoneCallType: PhoneCallPhoneCallType;
}

/**
 * Check if a given object implements the LocalProfilePreviewPhoneCall interface.
 */
export function instanceOfLocalProfilePreviewPhoneCall(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "phoneCallType" in value;

    return isInstance;
}

export function LocalProfilePreviewPhoneCallFromJSON(json: any): LocalProfilePreviewPhoneCall {
    return LocalProfilePreviewPhoneCallFromJSONTyped(json, false);
}

export function LocalProfilePreviewPhoneCallFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocalProfilePreviewPhoneCall {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'phoneNumber': !exists(json, 'phone_number') ? undefined : json['phone_number'],
        'safeNumber': !exists(json, 'safe_number') ? undefined : json['safe_number'],
        'createSafeNumberRequest': !exists(json, 'create_safe_number_request') ? undefined : PhoneCallCreateSafeNumberRequestFromJSON(json['create_safe_number_request']),
        'phoneCallType': PhoneCallPhoneCallTypeFromJSON(json['phone_call_type']),
    };
}

export function LocalProfilePreviewPhoneCallToJSON(value?: LocalProfilePreviewPhoneCall | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'phone_number': value.phoneNumber,
        'safe_number': value.safeNumber,
        'create_safe_number_request': PhoneCallCreateSafeNumberRequestToJSON(value.createSafeNumberRequest),
        'phone_call_type': PhoneCallPhoneCallTypeToJSON(value.phoneCallType),
    };
}

