/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * - COMMERCE_COUPON_USER_STATUS_UNSPECIFIED: 유저 상태를 알 수 없음
 *  - COMMERCE_COUPON_USER_STATUS_INACTIVE_COUPON: 유효하지 않은 쿠폰
 *  - COMMERCE_COUPON_USER_STATUS_CAN_USE: 쿠폰을 사용 가능
 *  - COMMERCE_COUPON_USER_STATUS_IS_USED: 이미 사용한 쿠폰
 *  - COMMERCE_COUPON_USER_STATUS_IS_EXPIRED: 만료된 쿠폰
 *  - COMMERCE_COUPON_USER_STATUS_NO_QUANTITY: 수량 부족
 *  - COMMERCE_COUPON_USER_STATUS_CAN_CLAIM: 발급 가능
 *  - COMMERCE_COUPON_USER_STATUS_CLAIMED_BUT_EXPIRED: 발급 했지만 만료됨
 * @export
 */
export const V1CommerceCouponUserStatus = {
    Unspecified: 'COMMERCE_COUPON_USER_STATUS_UNSPECIFIED',
    InactiveCoupon: 'COMMERCE_COUPON_USER_STATUS_INACTIVE_COUPON',
    CanUse: 'COMMERCE_COUPON_USER_STATUS_CAN_USE',
    IsUsed: 'COMMERCE_COUPON_USER_STATUS_IS_USED',
    IsExpired: 'COMMERCE_COUPON_USER_STATUS_IS_EXPIRED',
    NoQuantity: 'COMMERCE_COUPON_USER_STATUS_NO_QUANTITY',
    CanClaim: 'COMMERCE_COUPON_USER_STATUS_CAN_CLAIM',
    ClaimedButExpired: 'COMMERCE_COUPON_USER_STATUS_CLAIMED_BUT_EXPIRED'
} as const;
export type V1CommerceCouponUserStatus = typeof V1CommerceCouponUserStatus[keyof typeof V1CommerceCouponUserStatus];


export function V1CommerceCouponUserStatusFromJSON(json: any): V1CommerceCouponUserStatus {
    return V1CommerceCouponUserStatusFromJSONTyped(json, false);
}

export function V1CommerceCouponUserStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1CommerceCouponUserStatus {
    return json as V1CommerceCouponUserStatus;
}

export function V1CommerceCouponUserStatusToJSON(value?: V1CommerceCouponUserStatus | null): any {
    return value as any;
}

