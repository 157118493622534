export const SESSION_AUTH_TOKEN = 'authToken'

interface sessionStoreProps {
  key: string
  value: string
}
export const setSessionStore = ({ key, value }: sessionStoreProps) => {
  try {
    window.sessionStorage.setItem(key, value)
  } catch (_) {
    //
  }
}
export const getSessionStore = (key: string) => {
  try {
    return window.sessionStorage.getItem(key)
  } catch (_) {
    //
  }
}

export const removeSessionStore = (key: string) => {
  try {
    return window.sessionStorage.removeItem(key)
  } catch (_) {
    //
  }
}
