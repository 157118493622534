import {
  type LocalMapEventName,
  actionType,
  platforms,
  screenName,
  serviceNames,
  version,
  type ExceptionEventName,
  exceptionEventNames,
  type LocalMapEventPartsName,
} from './types'

const platformPattern = platforms.join('|')
const actionTypePattern = actionType.join('|')
const serviceNamePattern = serviceNames.join('|')
const screenNamePattern = screenName.join('|')
const versionPattern = version.join('|')

/**
 * LocalMapEventName 검증 함수
 */
export const isLocalMapEventName = (
  value: string
): value is LocalMapEventName => {
  // 예외 이벤트 네임을 먼저 검사합니다.
  if (exceptionEventNames.includes(value as ExceptionEventName)) {
    return true
  }

  // 정규식을 생성합니다.
  const pattern = new RegExp(
    `^(${platformPattern})_(${actionTypePattern})_(${serviceNamePattern})_(${screenNamePattern})(?:_(\\w+))*_(${versionPattern})$`
  )
  const match = value.match(pattern)

  return match !== null
}

/**
 * LocalMapEventPartsName 검증 함수
 */
export const isLocalMapEventPartsName = (
  value: string
): value is LocalMapEventPartsName => {
  const pattern = new RegExp(
    `^(${serviceNamePattern})_(${screenNamePattern})(?:_(\\w+))*$`
  )
  const match = value.match(pattern)

  return match !== null
}

const externalServices = ['fletmarket', 'serviceExploration', 'businessReview']
const externalServicesPattern = externalServices.join('|')
/**
 * 외부에서 유입되는 referrer 검증 함수
 */
export const isExternalReferrerName = (
  value: string
): value is LocalMapEventPartsName => {
  const pattern = new RegExp(
    `^(${externalServicesPattern})_(\\w+)(?:_(\\w+))*$`
  )
  const match = value.match(pattern)

  return match !== null
}
