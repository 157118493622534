import { type StateCreator } from 'zustand'

// TODO: DEFINE TYPES
export type ScreenDepthNameTypes = 'TO_BE_DECIDED'

interface ScreenDepthNameStore {
  screenDepthName: ScreenDepthNameTypes
  updateScreenDepthName: (name: ScreenDepthNameTypes) => void
}

export const screenDepthNameStore: StateCreator<ScreenDepthNameStore> = (
  set
) => ({
  screenDepthName: 'TO_BE_DECIDED',
  updateScreenDepthName: (name: ScreenDepthNameTypes) =>
    set((state) => ({ ...state, screenDepthName: name })),
})
