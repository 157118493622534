import React, { Suspense } from 'react'

const LazyDevtools = React.lazy(() => import('./Devtools'))

const DevtoolsRoot = () => {
  return (
    <Suspense>
      <LazyDevtools />
    </Suspense>
  )
}

export default DevtoolsRoot
