import { AppScreen } from '@stackflow/plugin-basic-ui'
import classNames from 'classnames'

import { clientEnvironment } from '@src/bridge/utils'
import { FeedStepKeys } from '@src/hooks/useStepRouter'
import { getCachedIOSSafeAreaInsetTop } from '@src/local-map-utils/safeAreaVariable'
import LocalMapBottomSheetSkeleton from '@src/pages/home-activity/bottom-sheet/LocalMapBottomSheetSkeleton'
import { useQueryParams } from '@src/react-utils/url/useQueryParams'

import * as css from './index.css'
import * as zIndexCss from './zIndex.css'
import SearchInputSkeleton from '../home-feed-step/search-input/HomeSearchInputSkeleton'
import SectionRecommendPostSkeleton from '../home-feed-step/section/section-recommend-post/SectionRecommendPostSkeleton'

const HomeSkeleton = () => {
  const { stepType: stepTypeParam } = useQueryParams<{
    stepType: FeedStepKeys
  }>()
  const stepType =
    stepTypeParam && FeedStepKeys.includes(stepTypeParam)
      ? stepTypeParam
      : 'home'

  const cachedSafeAreaInsetTop = getCachedIOSSafeAreaInsetTop()

  // iOS 초기 1회 덜그덕거리는 렌더링 수정
  if (
    clientEnvironment.isIOS &&
    (!cachedSafeAreaInsetTop || cachedSafeAreaInsetTop === '0px')
  ) {
    return null
  }

  return (
    <AppScreen>
      <div
        id="local-map-home-skeleton"
        className={classNames(zIndexCss.zIndexContainer, css.skeletonMap)}
      >
        <SearchInputSkeleton />
        <LocalMapBottomSheetSkeleton stepType={stepType}>
          {stepType === 'home' && <SectionRecommendPostSkeleton />}
        </LocalMapBottomSheetSkeleton>
      </div>
    </AppScreen>
  )
}

export default HomeSkeleton
