/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
/**
 * 
 * @export
 * @interface V1ExperimentSegment
 */
export interface V1ExperimentSegment {
    /**
     * 
     * @type {string}
     * @memberof V1ExperimentSegment
     */
    experimentKey: string;
    /**
     * 할당된 그룹 이름 eg) unassigned, control, treatment1, treatment2...
     * @type {string}
     * @memberof V1ExperimentSegment
     */
    assignedGroupName: string;
}

/**
 * Check if a given object implements the V1ExperimentSegment interface.
 */
export function instanceOfV1ExperimentSegment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "experimentKey" in value;
    isInstance = isInstance && "assignedGroupName" in value;

    return isInstance;
}

export function V1ExperimentSegmentFromJSON(json: any): V1ExperimentSegment {
    return V1ExperimentSegmentFromJSONTyped(json, false);
}

export function V1ExperimentSegmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ExperimentSegment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'experimentKey': json['experiment_key'],
        'assignedGroupName': json['assigned_group_name'],
    };
}

export function V1ExperimentSegmentToJSON(value?: V1ExperimentSegment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'experiment_key': value.experimentKey,
        'assigned_group_name': value.assignedGroupName,
    };
}

