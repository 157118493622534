/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
/**
 * 
 * @export
 * @interface SearchResultLocalProfileBestReview
 */
export interface SearchResultLocalProfileBestReview {
    /**
     * 
     * @type {string}
     * @memberof SearchResultLocalProfileBestReview
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof SearchResultLocalProfileBestReview
     */
    content: string;
}

/**
 * Check if a given object implements the SearchResultLocalProfileBestReview interface.
 */
export function instanceOfSearchResultLocalProfileBestReview(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "content" in value;

    return isInstance;
}

export function SearchResultLocalProfileBestReviewFromJSON(json: any): SearchResultLocalProfileBestReview {
    return SearchResultLocalProfileBestReviewFromJSONTyped(json, false);
}

export function SearchResultLocalProfileBestReviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchResultLocalProfileBestReview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'content': json['content'],
    };
}

export function SearchResultLocalProfileBestReviewToJSON(value?: SearchResultLocalProfileBestReview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'content': value.content,
    };
}

