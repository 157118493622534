/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { SocialCapitalRanking } from './SocialCapitalRanking';
import {
    SocialCapitalRankingFromJSON,
    SocialCapitalRankingFromJSONTyped,
    SocialCapitalRankingToJSON,
} from './SocialCapitalRanking';
import type { SocialCapitalRegion } from './SocialCapitalRegion';
import {
    SocialCapitalRegionFromJSON,
    SocialCapitalRegionFromJSONTyped,
    SocialCapitalRegionToJSON,
} from './SocialCapitalRegion';

/**
 * 
 * @export
 * @interface SocialCapitalRegionRanking
 */
export interface SocialCapitalRegionRanking {
    /**
     * 
     * @type {SocialCapitalRegion}
     * @memberof SocialCapitalRegionRanking
     */
    region: SocialCapitalRegion;
    /**
     * 
     * @type {SocialCapitalRanking}
     * @memberof SocialCapitalRegionRanking
     */
    ranking: SocialCapitalRanking;
}

/**
 * Check if a given object implements the SocialCapitalRegionRanking interface.
 */
export function instanceOfSocialCapitalRegionRanking(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "region" in value;
    isInstance = isInstance && "ranking" in value;

    return isInstance;
}

export function SocialCapitalRegionRankingFromJSON(json: any): SocialCapitalRegionRanking {
    return SocialCapitalRegionRankingFromJSONTyped(json, false);
}

export function SocialCapitalRegionRankingFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocialCapitalRegionRanking {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'region': SocialCapitalRegionFromJSON(json['region']),
        'ranking': SocialCapitalRankingFromJSON(json['ranking']),
    };
}

export function SocialCapitalRegionRankingToJSON(value?: SocialCapitalRegionRanking | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'region': SocialCapitalRegionToJSON(value.region),
        'ranking': SocialCapitalRankingToJSON(value.ranking),
    };
}

