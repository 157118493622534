/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
/**
 * 
 * @export
 * @interface V1VerifyEmployeeResponse
 */
export interface V1VerifyEmployeeResponse {
    /**
     * 
     * @type {boolean}
     * @memberof V1VerifyEmployeeResponse
     */
    isEmployee: boolean;
}

/**
 * Check if a given object implements the V1VerifyEmployeeResponse interface.
 */
export function instanceOfV1VerifyEmployeeResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "isEmployee" in value;

    return isInstance;
}

export function V1VerifyEmployeeResponseFromJSON(json: any): V1VerifyEmployeeResponse {
    return V1VerifyEmployeeResponseFromJSONTyped(json, false);
}

export function V1VerifyEmployeeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1VerifyEmployeeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isEmployee': json['is_employee'],
    };
}

export function V1VerifyEmployeeResponseToJSON(value?: V1VerifyEmployeeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_employee': value.isEmployee,
    };
}

