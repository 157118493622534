import { localMapApi } from '@src/api'
import { type BridgeInfoType } from '@src/bridge/context/BridgeInfoProvider'

export interface GetUserLocationProps {
  bridgeInfo: BridgeInfoType
}
export type GetUserLocationResponse = ReturnType<typeof getUserLocation>

export function getUserLocation({ bridgeInfo }: GetUserLocationProps) {
  return localMapApi(bridgeInfo).localMapServiceListUserLocations()
}
