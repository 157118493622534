/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
/**
 * 
 * @export
 * @interface PhoneCallCreateSafeNumberRequest
 */
export interface PhoneCallCreateSafeNumberRequest {
    /**
     * 
     * @type {string}
     * @memberof PhoneCallCreateSafeNumberRequest
     */
    businessProfileId: string;
    /**
     * 
     * @type {string}
     * @memberof PhoneCallCreateSafeNumberRequest
     */
    loggingCallbackUrl?: string;
}

/**
 * Check if a given object implements the PhoneCallCreateSafeNumberRequest interface.
 */
export function instanceOfPhoneCallCreateSafeNumberRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "businessProfileId" in value;

    return isInstance;
}

export function PhoneCallCreateSafeNumberRequestFromJSON(json: any): PhoneCallCreateSafeNumberRequest {
    return PhoneCallCreateSafeNumberRequestFromJSONTyped(json, false);
}

export function PhoneCallCreateSafeNumberRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhoneCallCreateSafeNumberRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'businessProfileId': json['business_profile_id'],
        'loggingCallbackUrl': !exists(json, 'logging_callback_url') ? undefined : json['logging_callback_url'],
    };
}

export function PhoneCallCreateSafeNumberRequestToJSON(value?: PhoneCallCreateSafeNumberRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'business_profile_id': value.businessProfileId,
        'logging_callback_url': value.loggingCallbackUrl,
    };
}

