import * as Sentry from '@sentry/react'

import { type BridgeInfoType } from '@src/bridge/context/BridgeInfoProvider'
import { CLARITY_ENABLED } from '@src/constants'
import { setClarityUserConfig } from '@src/local-map-utils/analytics/clarity'
import { setDatadogRumUserConfig } from '@src/local-map-utils/analytics/dataDogRum'
import { getExternalReferrerEntry } from '@src/store/externalAccessValues'

/**
 * datadog, sentry, clarity 설정
 */
export const setTools = async ({ app, region, user }: BridgeInfoType) => {
  const { referrer, entry } = getExternalReferrerEntry()

  setDatadogRumUserConfig({
    userId: user.id,
    region: region.id,
    userAgent: app.userAgent,
    country: app.country || '',
    deviceIdentity: app.deviceIdentity || '',
    referrer,
    entry,
  })

  Sentry.setUser({
    id: user.id,
  })
  Sentry.setTags({
    appVersion: app.appVersion,
  })

  if (CLARITY_ENABLED) {
    setClarityUserConfig({
      userId: user.id.toString(),
      deviceIdentity: app.deviceIdentity,
      region: region.id.toString(),
      userAgent: app.userAgent,
      onError: (err) => {
        err.message = `[clarity 초기화 error]: ${err.message}`
        Sentry.captureException(err)
      },
    })
  }
}
