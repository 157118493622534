import { localMapApi } from '@src/api'
import { type BridgeInfoType } from '@src/bridge/context/BridgeInfoProvider'
import { type LocalMapServiceDeleteUserLocationByIdRequest } from '@src/network/src/__gen__/local-map'

export type DeleteUserLocationProps = {
  bridgeInfo: BridgeInfoType
  data: LocalMapServiceDeleteUserLocationByIdRequest
}

export type DeleteUserLocationResponse = ReturnType<
  typeof deleteUserLocationById
>

export function deleteUserLocationById({
  bridgeInfo,
  data,
}: DeleteUserLocationProps) {
  localMapApi(bridgeInfo).localMapServiceDeleteUserLocationById(data)
}
