/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * - LOCAL_PROFILE_PREVIEW_NOT_DISPLAYABLE_REASON_NOT_ACTIVE: 비활성화
 * @export
 */
export const LocalProfilePreviewLocalProfilePreviewNotDisplayableReason = {
    Unspecified: 'LOCAL_PROFILE_PREVIEW_NOT_DISPLAYABLE_REASON_UNSPECIFIED',
    NotActive: 'LOCAL_PROFILE_PREVIEW_NOT_DISPLAYABLE_REASON_NOT_ACTIVE'
} as const;
export type LocalProfilePreviewLocalProfilePreviewNotDisplayableReason = typeof LocalProfilePreviewLocalProfilePreviewNotDisplayableReason[keyof typeof LocalProfilePreviewLocalProfilePreviewNotDisplayableReason];


export function LocalProfilePreviewLocalProfilePreviewNotDisplayableReasonFromJSON(json: any): LocalProfilePreviewLocalProfilePreviewNotDisplayableReason {
    return LocalProfilePreviewLocalProfilePreviewNotDisplayableReasonFromJSONTyped(json, false);
}

export function LocalProfilePreviewLocalProfilePreviewNotDisplayableReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocalProfilePreviewLocalProfilePreviewNotDisplayableReason {
    return json as LocalProfilePreviewLocalProfilePreviewNotDisplayableReason;
}

export function LocalProfilePreviewLocalProfilePreviewNotDisplayableReasonToJSON(value?: LocalProfilePreviewLocalProfilePreviewNotDisplayableReason | null): any {
    return value as any;
}

