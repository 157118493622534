/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
/**
 * 
 * @export
 * @interface SocialCapitalRegion
 */
export interface SocialCapitalRegion {
    /**
     * 
     * @type {string}
     * @memberof SocialCapitalRegion
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SocialCapitalRegion
     */
    fullname: string;
    /**
     * 
     * @type {string}
     * @memberof SocialCapitalRegion
     */
    name3: string;
}

/**
 * Check if a given object implements the SocialCapitalRegion interface.
 */
export function instanceOfSocialCapitalRegion(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "fullname" in value;
    isInstance = isInstance && "name3" in value;

    return isInstance;
}

export function SocialCapitalRegionFromJSON(json: any): SocialCapitalRegion {
    return SocialCapitalRegionFromJSONTyped(json, false);
}

export function SocialCapitalRegionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocialCapitalRegion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'fullname': json['fullname'],
        'name3': json['name3'],
    };
}

export function SocialCapitalRegionToJSON(value?: SocialCapitalRegion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fullname': value.fullname,
        'name3': value.name3,
    };
}

