export const dom = {
  getPositionerId: (id: string) => `bottom-sheet:${id}:positioner`,
  getRootId: (id: string) => `bottom-sheet:${id}:root`,
  getHandleId: (id: string) => `bottom-sheet:${id}:handle`,
  getContentId: (id: string) => `bottom-sheet:${id}:content`,
  getPositionerHeight: (id: string) => {
    const positioner = document.getElementById(dom.getPositionerId(id))
    if (positioner === null) return document.body.clientHeight
    const positionerRect = positioner.getBoundingClientRect()
    return positionerRect.height
  },
  getHandleHeight: (id: string) => {
    const handle = document.getElementById(dom.getHandleId(id))
    if (handle === null) return 0
    const handleRect = handle.getBoundingClientRect()
    return handleRect.height
  },
  getContentScrollHeight: (id: string) => {
    const content = document.getElementById(dom.getContentId(id))
    if (content === null) return 0

    return content.scrollHeight
  },
  getComputedPosition: (id: string, position: number) => ({
    positionFromTop: position,
    positionFromBottom: dom.getPositionerHeight(id) - position,
  }),
}
