/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1LocalProfileBusinessReviewPreview } from './V1LocalProfileBusinessReviewPreview';
import {
    V1LocalProfileBusinessReviewPreviewFromJSON,
    V1LocalProfileBusinessReviewPreviewFromJSONTyped,
    V1LocalProfileBusinessReviewPreviewToJSON,
} from './V1LocalProfileBusinessReviewPreview';

/**
 * 
 * @export
 * @interface V1GetLocalProfileBusinessReviewPreviewResponse
 */
export interface V1GetLocalProfileBusinessReviewPreviewResponse {
    /**
     * 
     * @type {V1LocalProfileBusinessReviewPreview}
     * @memberof V1GetLocalProfileBusinessReviewPreviewResponse
     */
    localProfileBusinessReviewPreview: V1LocalProfileBusinessReviewPreview;
}

/**
 * Check if a given object implements the V1GetLocalProfileBusinessReviewPreviewResponse interface.
 */
export function instanceOfV1GetLocalProfileBusinessReviewPreviewResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "localProfileBusinessReviewPreview" in value;

    return isInstance;
}

export function V1GetLocalProfileBusinessReviewPreviewResponseFromJSON(json: any): V1GetLocalProfileBusinessReviewPreviewResponse {
    return V1GetLocalProfileBusinessReviewPreviewResponseFromJSONTyped(json, false);
}

export function V1GetLocalProfileBusinessReviewPreviewResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1GetLocalProfileBusinessReviewPreviewResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'localProfileBusinessReviewPreview': V1LocalProfileBusinessReviewPreviewFromJSON(json['local_profile_business_review_preview']),
    };
}

export function V1GetLocalProfileBusinessReviewPreviewResponseToJSON(value?: V1GetLocalProfileBusinessReviewPreviewResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'local_profile_business_review_preview': V1LocalProfileBusinessReviewPreviewToJSON(value.localProfileBusinessReviewPreview),
    };
}

