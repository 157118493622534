import {
  type QueryIdState,
  type QueryIdAction,
  queryIdReducer,
  initialQueryIdState,
} from '@daangn/search-log-sdk'
import { type StateCreator } from 'zustand'

interface QueryIdStore extends QueryIdState {
  dispatchQueryId: (action: QueryIdAction) => void
}

export const queryIdStore: StateCreator<QueryIdStore> = (set) => ({
  queryId: initialQueryIdState.queryId,
  dispatchQueryId: (action: QueryIdAction) =>
    set((state) => queryIdReducer(state, action)),
})
