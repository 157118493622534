/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
/**
 * 
 * @export
 * @interface V1AddressAutoComplete
 */
export interface V1AddressAutoComplete {
    /**
     * 
     * @type {string}
     * @memberof V1AddressAutoComplete
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof V1AddressAutoComplete
     */
    addressId: string;
    /**
     * 
     * @type {string}
     * @memberof V1AddressAutoComplete
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof V1AddressAutoComplete
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof V1AddressAutoComplete
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof V1AddressAutoComplete
     */
    subtitle?: string;
}

/**
 * Check if a given object implements the V1AddressAutoComplete interface.
 */
export function instanceOfV1AddressAutoComplete(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "addressId" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "location" in value;
    isInstance = isInstance && "title" in value;

    return isInstance;
}

export function V1AddressAutoCompleteFromJSON(json: any): V1AddressAutoComplete {
    return V1AddressAutoCompleteFromJSONTyped(json, false);
}

export function V1AddressAutoCompleteFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1AddressAutoComplete {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'addressId': json['address_id'],
        'type': json['type'],
        'location': json['location'],
        'title': json['title'],
        'subtitle': !exists(json, 'subtitle') ? undefined : json['subtitle'],
    };
}

export function V1AddressAutoCompleteToJSON(value?: V1AddressAutoComplete | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'address_id': value.addressId,
        'type': value.type,
        'location': value.location,
        'title': value.title,
        'subtitle': value.subtitle,
    };
}

