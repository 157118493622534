import { localMapApi } from '@src/api'
import { type BridgeInfoType } from '@src/bridge/context/BridgeInfoProvider'
import { type LocalMapServiceUpdateUserLocationRequest } from '@src/network/src/__gen__/local-map'

export type UpdateUserLocationProps = {
  bridgeInfo: BridgeInfoType
  data: LocalMapServiceUpdateUserLocationRequest
}

export type UpdateUserLocationResponse = ReturnType<typeof updateUserLocation>

export function updateUserLocation({
  bridgeInfo,
  data,
}: UpdateUserLocationProps) {
  return localMapApi(bridgeInfo).localMapServiceUpdateUserLocation(data)
}
