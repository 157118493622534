import { type StateCreator } from 'zustand'

import { type LocalMapSearchQueryFromIdType } from '../types/search'

interface QueryFromIdStore {
  queryFromId: LocalMapSearchQueryFromIdType
  dispatchQueryFromId: (action: QueryFromIdAction) => void
}

export type QueryFromIdState = {
  queryFromId: LocalMapSearchQueryFromIdType
}

export type QueryFromIdAction = { type: 'update'; payload: QueryFromIdState }

export const initialQueryFromIdState = {
  queryFromId: 'unknown' as LocalMapSearchQueryFromIdType,
}

export const queryFromIdReducer = (
  state: QueryFromIdState,
  action: QueryFromIdAction
) => {
  switch (action.type) {
    case 'update': {
      return { ...state, queryFromId: action.payload.queryFromId }
    }
    default:
      throw new Error('Given action type does not exist.')
  }
}

export const queryFromIdStore: StateCreator<QueryFromIdStore> = (set) => ({
  queryFromId: initialQueryFromIdState.queryFromId,
  dispatchQueryFromId: (action: QueryFromIdAction) =>
    set((state) => queryFromIdReducer(state, action)),
})
