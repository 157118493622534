/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { ThemeBusinessPostSectionThemeBusinessPost } from './ThemeBusinessPostSectionThemeBusinessPost';
import {
    ThemeBusinessPostSectionThemeBusinessPostFromJSON,
    ThemeBusinessPostSectionThemeBusinessPostFromJSONTyped,
    ThemeBusinessPostSectionThemeBusinessPostToJSON,
} from './ThemeBusinessPostSectionThemeBusinessPost';

/**
 * 
 * @export
 * @interface V1ThemeBusinessPostSection
 */
export interface V1ThemeBusinessPostSection {
    /**
     * 
     * @type {Array<ThemeBusinessPostSectionThemeBusinessPost>}
     * @memberof V1ThemeBusinessPostSection
     */
    themeBusinessPosts: Array<ThemeBusinessPostSectionThemeBusinessPost>;
    /**
     * 
     * @type {string}
     * @memberof V1ThemeBusinessPostSection
     */
    region3Name: string;
    /**
     * 
     * @type {string}
     * @memberof V1ThemeBusinessPostSection
     */
    region3Id: string;
}

/**
 * Check if a given object implements the V1ThemeBusinessPostSection interface.
 */
export function instanceOfV1ThemeBusinessPostSection(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "themeBusinessPosts" in value;
    isInstance = isInstance && "region3Name" in value;
    isInstance = isInstance && "region3Id" in value;

    return isInstance;
}

export function V1ThemeBusinessPostSectionFromJSON(json: any): V1ThemeBusinessPostSection {
    return V1ThemeBusinessPostSectionFromJSONTyped(json, false);
}

export function V1ThemeBusinessPostSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ThemeBusinessPostSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'themeBusinessPosts': ((json['theme_business_posts'] as Array<any>).map(ThemeBusinessPostSectionThemeBusinessPostFromJSON)),
        'region3Name': json['region3_name'],
        'region3Id': json['region3_id'],
    };
}

export function V1ThemeBusinessPostSectionToJSON(value?: V1ThemeBusinessPostSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'theme_business_posts': ((value.themeBusinessPosts as Array<any>).map(ThemeBusinessPostSectionThemeBusinessPostToJSON)),
        'region3_name': value.region3Name,
        'region3_id': value.region3Id,
    };
}

