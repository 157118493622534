/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
/**
 * 
 * @export
 * @interface V1GetSafeNumberByBusinessProfileIdResponse
 */
export interface V1GetSafeNumberByBusinessProfileIdResponse {
    /**
     * 
     * @type {string}
     * @memberof V1GetSafeNumberByBusinessProfileIdResponse
     */
    safeNumber: string;
}

/**
 * Check if a given object implements the V1GetSafeNumberByBusinessProfileIdResponse interface.
 */
export function instanceOfV1GetSafeNumberByBusinessProfileIdResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "safeNumber" in value;

    return isInstance;
}

export function V1GetSafeNumberByBusinessProfileIdResponseFromJSON(json: any): V1GetSafeNumberByBusinessProfileIdResponse {
    return V1GetSafeNumberByBusinessProfileIdResponseFromJSONTyped(json, false);
}

export function V1GetSafeNumberByBusinessProfileIdResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1GetSafeNumberByBusinessProfileIdResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'safeNumber': json['safe_number'],
    };
}

export function V1GetSafeNumberByBusinessProfileIdResponseToJSON(value?: V1GetSafeNumberByBusinessProfileIdResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'safe_number': value.safeNumber,
    };
}

