/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1UserLocation } from './V1UserLocation';
import {
    V1UserLocationFromJSON,
    V1UserLocationFromJSONTyped,
    V1UserLocationToJSON,
} from './V1UserLocation';

/**
 * 
 * @export
 * @interface V1ListUserLocationsResponse
 */
export interface V1ListUserLocationsResponse {
    /**
     * 
     * @type {Array<V1UserLocation>}
     * @memberof V1ListUserLocationsResponse
     */
    userLocations: Array<V1UserLocation>;
    /**
     * 
     * @type {boolean}
     * @memberof V1ListUserLocationsResponse
     */
    hasDisagreedTerms?: boolean;
}

/**
 * Check if a given object implements the V1ListUserLocationsResponse interface.
 */
export function instanceOfV1ListUserLocationsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userLocations" in value;

    return isInstance;
}

export function V1ListUserLocationsResponseFromJSON(json: any): V1ListUserLocationsResponse {
    return V1ListUserLocationsResponseFromJSONTyped(json, false);
}

export function V1ListUserLocationsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ListUserLocationsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userLocations': ((json['user_locations'] as Array<any>).map(V1UserLocationFromJSON)),
        'hasDisagreedTerms': !exists(json, 'hasDisagreedTerms') ? undefined : json['hasDisagreedTerms'],
    };
}

export function V1ListUserLocationsResponseToJSON(value?: V1ListUserLocationsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_locations': ((value.userLocations as Array<any>).map(V1UserLocationToJSON)),
        'hasDisagreedTerms': value.hasDisagreedTerms,
    };
}

