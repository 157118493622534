/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { TypeLatLng } from './TypeLatLng';
import {
    TypeLatLngFromJSON,
    TypeLatLngFromJSONTyped,
    TypeLatLngToJSON,
} from './TypeLatLng';

/**
 * 
 * @export
 * @interface V1MapBounds
 */
export interface V1MapBounds {
    /**
     * 
     * @type {TypeLatLng}
     * @memberof V1MapBounds
     */
    center: TypeLatLng;
    /**
     * 
     * @type {TypeLatLng}
     * @memberof V1MapBounds
     */
    northWest: TypeLatLng;
    /**
     * 
     * @type {TypeLatLng}
     * @memberof V1MapBounds
     */
    southEast: TypeLatLng;
}

/**
 * Check if a given object implements the V1MapBounds interface.
 */
export function instanceOfV1MapBounds(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "center" in value;
    isInstance = isInstance && "northWest" in value;
    isInstance = isInstance && "southEast" in value;

    return isInstance;
}

export function V1MapBoundsFromJSON(json: any): V1MapBounds {
    return V1MapBoundsFromJSONTyped(json, false);
}

export function V1MapBoundsFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1MapBounds {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'center': TypeLatLngFromJSON(json['center']),
        'northWest': TypeLatLngFromJSON(json['north_west']),
        'southEast': TypeLatLngFromJSON(json['south_east']),
    };
}

export function V1MapBoundsToJSON(value?: V1MapBounds | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'center': TypeLatLngToJSON(value.center),
        'north_west': TypeLatLngToJSON(value.northWest),
        'south_east': TypeLatLngToJSON(value.southEast),
    };
}

