/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1UserLocation } from './V1UserLocation';
import {
    V1UserLocationFromJSON,
    V1UserLocationFromJSONTyped,
    V1UserLocationToJSON,
} from './V1UserLocation';

/**
 * 
 * @export
 * @interface V1UpdateUserLocationResponse
 */
export interface V1UpdateUserLocationResponse {
    /**
     * 
     * @type {V1UserLocation}
     * @memberof V1UpdateUserLocationResponse
     */
    userLocation: V1UserLocation;
}

/**
 * Check if a given object implements the V1UpdateUserLocationResponse interface.
 */
export function instanceOfV1UpdateUserLocationResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userLocation" in value;

    return isInstance;
}

export function V1UpdateUserLocationResponseFromJSON(json: any): V1UpdateUserLocationResponse {
    return V1UpdateUserLocationResponseFromJSONTyped(json, false);
}

export function V1UpdateUserLocationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1UpdateUserLocationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userLocation': V1UserLocationFromJSON(json['user_location']),
    };
}

export function V1UpdateUserLocationResponseToJSON(value?: V1UpdateUserLocationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_location': V1UserLocationToJSON(value.userLocation),
    };
}

