import { localMapApi } from '@src/api'
import { type BridgeInfoType } from '@src/bridge/context/BridgeInfoProvider'

import { type ExperimentGroupName, type ExperimentsState } from './coreTypes'

const SERVER_DRIVEN_EXPERIMENT_KEY = ['EMPTY'] as const
export type ServerDrivenExperimentKey =
  (typeof SERVER_DRIVEN_EXPERIMENT_KEY)[number]

export const getServerDrivenExperiments = async (
  bridgeInfo: BridgeInfoType
): Promise<ExperimentsState<ServerDrivenExperimentKey>> => {
  const response =
    await localMapApi(bridgeInfo).localMapServiceGetExperimentSegments()

  const nomalrizedExperiments = response.experimentSegments.reduce<
    ExperimentsState<ServerDrivenExperimentKey>
  >((acc, experiment) => {
    const experimentKey = isKnownServerDrivenKey(experiment.experimentKey)
      ? experiment.experimentKey
      : null

    if (experimentKey === null) {
      return acc
    }

    acc[experimentKey] = {
      experimentKey,
      assignedGroupName: experiment.assignedGroupName as ExperimentGroupName,
      segments: [
        {
          desc: 'control',
          groupName: 'control',
        },
        {
          desc: 'treatment1',
          groupName: 'treatment1',
        },
        {
          desc: 'treatment2',
          groupName: 'treatment2',
        },
      ],
    }

    return acc
  }, {} as ExperimentsState<ServerDrivenExperimentKey>)

  return nomalrizedExperiments
}

const isKnownServerDrivenKey = (
  key: string
): key is ServerDrivenExperimentKey =>
  SERVER_DRIVEN_EXPERIMENT_KEY.includes(key as ServerDrivenExperimentKey)
