/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1SectionItem } from './V1SectionItem';
import {
    V1SectionItemFromJSON,
    V1SectionItemFromJSONTyped,
    V1SectionItemToJSON,
} from './V1SectionItem';

/**
 * 
 * @export
 * @interface V1ListItemsBySectionViewTypesResponse
 */
export interface V1ListItemsBySectionViewTypesResponse {
    /**
     * 
     * @type {Array<V1SectionItem>}
     * @memberof V1ListItemsBySectionViewTypesResponse
     */
    sectionItems: Array<V1SectionItem>;
}

/**
 * Check if a given object implements the V1ListItemsBySectionViewTypesResponse interface.
 */
export function instanceOfV1ListItemsBySectionViewTypesResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sectionItems" in value;

    return isInstance;
}

export function V1ListItemsBySectionViewTypesResponseFromJSON(json: any): V1ListItemsBySectionViewTypesResponse {
    return V1ListItemsBySectionViewTypesResponseFromJSONTyped(json, false);
}

export function V1ListItemsBySectionViewTypesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ListItemsBySectionViewTypesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sectionItems': ((json['section_items'] as Array<any>).map(V1SectionItemFromJSON)),
    };
}

export function V1ListItemsBySectionViewTypesResponseToJSON(value?: V1ListItemsBySectionViewTypesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'section_items': ((value.sectionItems as Array<any>).map(V1SectionItemToJSON)),
    };
}

