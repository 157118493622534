/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1SearchAutoCompleteResultKeyword } from './V1SearchAutoCompleteResultKeyword';
import {
    V1SearchAutoCompleteResultKeywordFromJSON,
    V1SearchAutoCompleteResultKeywordFromJSONTyped,
    V1SearchAutoCompleteResultKeywordToJSON,
} from './V1SearchAutoCompleteResultKeyword';
import type { V1SearchAutoCompleteResultLocalProfile } from './V1SearchAutoCompleteResultLocalProfile';
import {
    V1SearchAutoCompleteResultLocalProfileFromJSON,
    V1SearchAutoCompleteResultLocalProfileFromJSONTyped,
    V1SearchAutoCompleteResultLocalProfileToJSON,
} from './V1SearchAutoCompleteResultLocalProfile';
import type { V1SearchAutoCompleteViewType } from './V1SearchAutoCompleteViewType';
import {
    V1SearchAutoCompleteViewTypeFromJSON,
    V1SearchAutoCompleteViewTypeFromJSONTyped,
    V1SearchAutoCompleteViewTypeToJSON,
} from './V1SearchAutoCompleteViewType';

/**
 * 
 * @export
 * @interface V1SearchAutoCompleteResultItem
 */
export interface V1SearchAutoCompleteResultItem {
    /**
     * 
     * @type {V1SearchAutoCompleteViewType}
     * @memberof V1SearchAutoCompleteResultItem
     */
    searchAutoCompleteViewType: V1SearchAutoCompleteViewType;
    /**
     * 
     * @type {V1SearchAutoCompleteResultKeyword}
     * @memberof V1SearchAutoCompleteResultItem
     */
    searchAutoCompleteResultKeyword?: V1SearchAutoCompleteResultKeyword;
    /**
     * 
     * @type {V1SearchAutoCompleteResultLocalProfile}
     * @memberof V1SearchAutoCompleteResultItem
     */
    searchAutoCompleteResultLocalProfile?: V1SearchAutoCompleteResultLocalProfile;
}

/**
 * Check if a given object implements the V1SearchAutoCompleteResultItem interface.
 */
export function instanceOfV1SearchAutoCompleteResultItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "searchAutoCompleteViewType" in value;

    return isInstance;
}

export function V1SearchAutoCompleteResultItemFromJSON(json: any): V1SearchAutoCompleteResultItem {
    return V1SearchAutoCompleteResultItemFromJSONTyped(json, false);
}

export function V1SearchAutoCompleteResultItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1SearchAutoCompleteResultItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchAutoCompleteViewType': V1SearchAutoCompleteViewTypeFromJSON(json['search_auto_complete_view_type']),
        'searchAutoCompleteResultKeyword': !exists(json, 'search_auto_complete_result_keyword') ? undefined : V1SearchAutoCompleteResultKeywordFromJSON(json['search_auto_complete_result_keyword']),
        'searchAutoCompleteResultLocalProfile': !exists(json, 'search_auto_complete_result_local_profile') ? undefined : V1SearchAutoCompleteResultLocalProfileFromJSON(json['search_auto_complete_result_local_profile']),
    };
}

export function V1SearchAutoCompleteResultItemToJSON(value?: V1SearchAutoCompleteResultItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'search_auto_complete_view_type': V1SearchAutoCompleteViewTypeToJSON(value.searchAutoCompleteViewType),
        'search_auto_complete_result_keyword': V1SearchAutoCompleteResultKeywordToJSON(value.searchAutoCompleteResultKeyword),
        'search_auto_complete_result_local_profile': V1SearchAutoCompleteResultLocalProfileToJSON(value.searchAutoCompleteResultLocalProfile),
    };
}

