import commonHeadersPlugin from '@daangn/plantae-plugin-common-headers'

import { karrotBridge } from '@src/bridge/bridge'

const plantaeCommonHeadersPlugin = () => {
  return commonHeadersPlugin({
    bridge: karrotBridge,
  })
}

export default plantaeCommonHeadersPlugin
