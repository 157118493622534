import {
  Spinner,
  type SeedSpinnerProps,
} from '@daangn/sprout-components-spinner'
import classnames from 'classnames'
import { memo } from 'react'

import * as css from './Loading.css'

interface LoadingProps extends SeedSpinnerProps {
  className?: string
}

export const Loading = memo(
  ({ className, variant = 'gray', size = 'small', ...props }: LoadingProps) => {
    return (
      <div className={classnames([css.fullHeightProgressWrapper, className])}>
        <Spinner variant={variant} size={size} {...props} />
      </div>
    )
  }
)
