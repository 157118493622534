type SafeAreaDirection = 'top' | 'bottom' | 'left' | 'right'

export const getSafeAreaValue = (direction: SafeAreaDirection) => {
  const rootElement = document.getElementById('root')
  const safeAreaDefault =
    rootElement &&
    window
      .getComputedStyle(rootElement)
      .getPropertyValue(`--safe-area-inset-${direction}`)
  const safeAreaEnv =
    rootElement &&
    window
      .getComputedStyle(rootElement)
      .getPropertyValue(`--safe-area-inset-${direction}-env`)
  const safeAreaConstant =
    rootElement &&
    window
      .getComputedStyle(rootElement)
      .getPropertyValue(`--safe-area-inset-${direction}-constant`)

  return safeAreaDefault || safeAreaEnv || safeAreaConstant || '0px'
}
