/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { SocialCapitalRanking } from './SocialCapitalRanking';
import {
    SocialCapitalRankingFromJSON,
    SocialCapitalRankingFromJSONTyped,
    SocialCapitalRankingToJSON,
} from './SocialCapitalRanking';
import type { SocialCapitalRegionRanking } from './SocialCapitalRegionRanking';
import {
    SocialCapitalRegionRankingFromJSON,
    SocialCapitalRegionRankingFromJSONTyped,
    SocialCapitalRegionRankingToJSON,
} from './SocialCapitalRegionRanking';

/**
 * 
 * @export
 * @interface NearbyRegionSectionNearbyRanking
 */
export interface NearbyRegionSectionNearbyRanking {
    /**
     * 
     * @type {Array<SocialCapitalRegionRanking>}
     * @memberof NearbyRegionSectionNearbyRanking
     */
    aboveTargetRegionRankings: Array<SocialCapitalRegionRanking>;
    /**
     * 
     * @type {SocialCapitalRanking}
     * @memberof NearbyRegionSectionNearbyRanking
     */
    targetRegionRanking: SocialCapitalRanking;
    /**
     * 
     * @type {Array<SocialCapitalRegionRanking>}
     * @memberof NearbyRegionSectionNearbyRanking
     */
    belowTargetRegionRankings: Array<SocialCapitalRegionRanking>;
}

/**
 * Check if a given object implements the NearbyRegionSectionNearbyRanking interface.
 */
export function instanceOfNearbyRegionSectionNearbyRanking(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "aboveTargetRegionRankings" in value;
    isInstance = isInstance && "targetRegionRanking" in value;
    isInstance = isInstance && "belowTargetRegionRankings" in value;

    return isInstance;
}

export function NearbyRegionSectionNearbyRankingFromJSON(json: any): NearbyRegionSectionNearbyRanking {
    return NearbyRegionSectionNearbyRankingFromJSONTyped(json, false);
}

export function NearbyRegionSectionNearbyRankingFromJSONTyped(json: any, ignoreDiscriminator: boolean): NearbyRegionSectionNearbyRanking {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aboveTargetRegionRankings': ((json['above_target_region_rankings'] as Array<any>).map(SocialCapitalRegionRankingFromJSON)),
        'targetRegionRanking': SocialCapitalRankingFromJSON(json['target_region_ranking']),
        'belowTargetRegionRankings': ((json['below_target_region_rankings'] as Array<any>).map(SocialCapitalRegionRankingFromJSON)),
    };
}

export function NearbyRegionSectionNearbyRankingToJSON(value?: NearbyRegionSectionNearbyRanking | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'above_target_region_rankings': ((value.aboveTargetRegionRankings as Array<any>).map(SocialCapitalRegionRankingToJSON)),
        'target_region_ranking': SocialCapitalRankingToJSON(value.targetRegionRanking),
        'below_target_region_rankings': ((value.belowTargetRegionRankings as Array<any>).map(SocialCapitalRegionRankingToJSON)),
    };
}

