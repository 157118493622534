import { type KarrotBridgeSchema } from '@daangn/karrotbridge/lib/__generated__/makeKarrotBridge'

import { type Nullable } from '@src/ts-utils/types/utilTypes'

export type BridgeAppInfo =
  KarrotBridgeSchema['ReqInfoAppResponse']['info']['app']
export type BridgeRegionInfo =
  KarrotBridgeSchema['ReqInfoRegionResponse']['info']['region']
export type BridgeUserInfo =
  KarrotBridgeSchema['ReqInfoUserResponse']['info']['user']

/**
 * 개발용 karrotBridge.getAppInfo()
 */
export const DEFAULT_APP: Nullable<BridgeAppInfo> = import.meta.env
  .VITE_DEFAULT_USER_AGENT
  ? Object.freeze({
      userAgent: import.meta.env.VITE_DEFAULT_USER_AGENT,
      deviceIdentity: import.meta.env.VITE_DEFAULT_DEVICE_IDENTITY ?? 'local',
      appHost: '',
      appVersion: 'local',
      appVersionCode: 'local',
      country: 'KR',
      locale: 'ko',
    })
  : null

/**
 * 개발용 karrotBridge.getUserInfo()
 */
export const DEFAULT_USER: Nullable<Partial<BridgeUserInfo>> = import.meta.env
  .VITE_DEFAULT_USER_ID
  ? Object.freeze({
      id: Number(import.meta.env.VITE_DEFAULT_USER_ID),
      nickname: import.meta.env.VITE_DEFAULT_USER_NICKNAME ?? undefined,
      authToken: import.meta.env.VITE_DEFAULT_AUTH_TOKEN ?? undefined,
    })
  : null

/**
 * 개발용 karrotBridge.getRegionInfo()
 */
export const DEFAULT_REGION: Nullable<Partial<BridgeRegionInfo>> = (() => {
  if (!import.meta.env.VITE_DEFAULT_REGION_ID) {
    return null
  }

  const centerCoordinate =
    import.meta.env.VITE_DEFAULT_REGION_CENTER_COORDINATES?.split(',')
  const [latitude, longitude] = centerCoordinate ?? [undefined, undefined]

  return Object.freeze({
    id: import.meta.env.VITE_DEFAULT_REGION_ID
      ? Number(import.meta.env.VITE_DEFAULT_REGION_ID)
      : -1,
    name: import.meta.env.VITE_DEFAULT_REGION_NAME ?? undefined,
    centerCoordinates:
      latitude && longitude
        ? {
            latitude: Number(latitude),
            longitude: Number(longitude),
          }
        : undefined,
  })
})()

/**
 * 개발용 karrotBridge.getCurrentPosition()
 */
export const DEFAULT_GEOLOCATION = (() => {
  if (!import.meta.env.VITE_DEFAULT_GEOLOCATION) {
    return undefined
  }

  const [latitude, longitude] = import.meta.env.VITE_DEFAULT_GEOLOCATION.split(
    ','
  )

  return Object.freeze({
    currentPosition: {
      position: {
        latitude: Number(latitude),
        longitude: Number(longitude),
      },
    },
  })
})()
