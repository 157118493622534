/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1LocalProfilePreview } from './V1LocalProfilePreview';
import {
    V1LocalProfilePreviewFromJSON,
    V1LocalProfilePreviewFromJSONTyped,
    V1LocalProfilePreviewToJSON,
} from './V1LocalProfilePreview';

/**
 * 
 * @export
 * @interface V1GetLocalProfilePreviewResponse
 */
export interface V1GetLocalProfilePreviewResponse {
    /**
     * 
     * @type {V1LocalProfilePreview}
     * @memberof V1GetLocalProfilePreviewResponse
     */
    localProfilePreview: V1LocalProfilePreview;
}

/**
 * Check if a given object implements the V1GetLocalProfilePreviewResponse interface.
 */
export function instanceOfV1GetLocalProfilePreviewResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "localProfilePreview" in value;

    return isInstance;
}

export function V1GetLocalProfilePreviewResponseFromJSON(json: any): V1GetLocalProfilePreviewResponse {
    return V1GetLocalProfilePreviewResponseFromJSONTyped(json, false);
}

export function V1GetLocalProfilePreviewResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1GetLocalProfilePreviewResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'localProfilePreview': V1LocalProfilePreviewFromJSON(json['local_profile_preview']),
    };
}

export function V1GetLocalProfilePreviewResponseToJSON(value?: V1GetLocalProfilePreviewResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'local_profile_preview': V1LocalProfilePreviewToJSON(value.localProfilePreview),
    };
}

