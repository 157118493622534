import { type ZodSchema } from 'zod'

// stale-while-revalidate 캐시
export async function cacheLocalStorageWithStaleWhileRevalidate<T>(
  key: string,
  fetchFunc: () => Promise<T>,
  schema: ZodSchema<T>
): Promise<T> {
  const dataFromCache = localStorage.getItem(key)

  if (
    dataFromCache !== null &&
    dataFromCache !== undefined &&
    dataFromCache !== ''
  ) {
    const parsedData = schema.safeParse(JSON.parse(dataFromCache))

    // validation 이후 캐시된 데이터 반환
    if (parsedData.success) {
      apiCallAndSave(key, fetchFunc)
      return parsedData.data
    }

    // validation 실패 시 패치 이후 데이터 반환
    const freshData = await apiCallAndSave(key, fetchFunc)
    return freshData
  }

  // 캐시된 데이터가 없으면 데이터 패치
  const freshData = await fetchFunc()
  localStorage.setItem(key, JSON.stringify(freshData))
  return freshData
}

async function apiCallAndSave<T>(key: string, api: () => Promise<T>) {
  const data = await api()

  localStorage.setItem(key, JSON.stringify(data))

  return data
}
