/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */
export function makeLocalMapBridge({ driver, }) {
    return {
        driver,
        subscribeTabClick(req, listener) {
            return driver.onSubscribed("REQ.LOCAL_MAP.TAB_CLICK_EVENT_SUBSCRIBE", req, listener);
        },
    };
}
