/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { GetAddressByCoordinateResponseAddressLand } from './GetAddressByCoordinateResponseAddressLand';
import {
    GetAddressByCoordinateResponseAddressLandFromJSON,
    GetAddressByCoordinateResponseAddressLandFromJSONTyped,
    GetAddressByCoordinateResponseAddressLandToJSON,
} from './GetAddressByCoordinateResponseAddressLand';
import type { GetAddressByCoordinateResponseAddressRoad } from './GetAddressByCoordinateResponseAddressRoad';
import {
    GetAddressByCoordinateResponseAddressRoadFromJSON,
    GetAddressByCoordinateResponseAddressRoadFromJSONTyped,
    GetAddressByCoordinateResponseAddressRoadToJSON,
} from './GetAddressByCoordinateResponseAddressRoad';

/**
 * 
 * @export
 * @interface V1GetAddressByCoordinateResponse
 */
export interface V1GetAddressByCoordinateResponse {
    /**
     * 
     * @type {GetAddressByCoordinateResponseAddressRoad}
     * @memberof V1GetAddressByCoordinateResponse
     */
    addressRoad?: GetAddressByCoordinateResponseAddressRoad;
    /**
     * 
     * @type {GetAddressByCoordinateResponseAddressLand}
     * @memberof V1GetAddressByCoordinateResponse
     */
    addressLand?: GetAddressByCoordinateResponseAddressLand;
}

/**
 * Check if a given object implements the V1GetAddressByCoordinateResponse interface.
 */
export function instanceOfV1GetAddressByCoordinateResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function V1GetAddressByCoordinateResponseFromJSON(json: any): V1GetAddressByCoordinateResponse {
    return V1GetAddressByCoordinateResponseFromJSONTyped(json, false);
}

export function V1GetAddressByCoordinateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1GetAddressByCoordinateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'addressRoad': !exists(json, 'address_road') ? undefined : GetAddressByCoordinateResponseAddressRoadFromJSON(json['address_road']),
        'addressLand': !exists(json, 'address_land') ? undefined : GetAddressByCoordinateResponseAddressLandFromJSON(json['address_land']),
    };
}

export function V1GetAddressByCoordinateResponseToJSON(value?: V1GetAddressByCoordinateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address_road': GetAddressByCoordinateResponseAddressRoadToJSON(value.addressRoad),
        'address_land': GetAddressByCoordinateResponseAddressLandToJSON(value.addressLand),
    };
}

