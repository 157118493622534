import type { Activity } from '@stackflow/core'
import { stackDepthChangePlugin } from '@stackflow/plugin-stack-depth-change'

import { karrotBridge } from '@src/bridge/bridge'
import { clientEnvironment } from '@src/bridge/utils'

const handleRouteStyle = ({
  depth,
}: {
  depth: number
  activities: Activity[]
  theme: 'android' | 'cupertino'
}) => {
  if (clientEnvironment.isWeb) {
    return
  }

  karrotBridge.styleCurrentRouter({
    router: {
      backSwipable: depth <= 1,
      scrollable: false,
      navbar: false,
    },
  })
}

interface DepthChangePluginOptions {
  theme: 'android' | 'cupertino'
}

const depthChangePlugin = ({ theme }: DepthChangePluginOptions) =>
  stackDepthChangePlugin({
    onInit: ({ depth, activities }) => {
      handleRouteStyle({ depth, activities, theme })
    },
    onDepthChanged: ({ depth, activities }) => {
      handleRouteStyle({ depth, activities, theme })
    },
  })

export default depthChangePlugin
