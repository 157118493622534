/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const V1CommerceCouponEventType = {
    Unspecified: 'COMMERCE_COUPON_EVENT_TYPE_UNSPECIFIED',
    Normal: 'COMMERCE_COUPON_EVENT_TYPE_NORMAL',
    FollowerOnly: 'COMMERCE_COUPON_EVENT_TYPE_FOLLOWER_ONLY',
    TargetOnly: 'COMMERCE_COUPON_EVENT_TYPE_TARGET_ONLY',
    CustomAreaOnly: 'COMMERCE_COUPON_EVENT_TYPE_CUSTOM_AREA_ONLY'
} as const;
export type V1CommerceCouponEventType = typeof V1CommerceCouponEventType[keyof typeof V1CommerceCouponEventType];


export function V1CommerceCouponEventTypeFromJSON(json: any): V1CommerceCouponEventType {
    return V1CommerceCouponEventTypeFromJSONTyped(json, false);
}

export function V1CommerceCouponEventTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1CommerceCouponEventType {
    return json as V1CommerceCouponEventType;
}

export function V1CommerceCouponEventTypeToJSON(value?: V1CommerceCouponEventType | null): any {
    return value as any;
}

