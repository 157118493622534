export type ConcurrentState<T> =
  | {
      _t: 'pending'
    }
  | {
      _t: 'rejected'
      result: Error
    }
  | {
      _t: 'resolved'
      result: T
    }

/**
 * promise를 concurrent resource로 변환 (외부에서 promise를 실행시키고, 내부에서 사용시킬 때 사용)
 */
export function makeConcurrentResource<T>(promise: Promise<T>) {
  const makeConcurrentResource = initConcurrentResource<T>()

  return makeConcurrentResource(promise)
}

/**
 * concurrent resource를 react component 내부에서 만들어서 사용하고 싶을 때 사용
 */
export function initConcurrentResource<T>() {
  let state: ConcurrentState<T> = {
    _t: 'pending',
  }

  function makeConcurrentResource(promise: Promise<T>) {
    const suspender = promise.then(
      (r) => {
        state = {
          _t: 'resolved',
          result: r,
        }
      },
      (e) => {
        state = {
          _t: 'rejected',
          result: e,
        }
      }
    )

    return {
      read() {
        switch (state._t) {
          case 'pending':
            throw suspender
          case 'rejected':
            throw state.result
          case 'resolved':
            return state.result
        }
      },
    }
  }

  return makeConcurrentResource
}
