/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
/**
 * 
 * @export
 * @interface V1RecommendationChip
 */
export interface V1RecommendationChip {
    /**
     * 
     * @type {string}
     * @memberof V1RecommendationChip
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof V1RecommendationChip
     */
    imageUrl: string;
    /**
     * 칩 클릭시 이동할 uri 
     * null일 경우 칩 이름으로 검색을 해야해요.
     * @type {string}
     * @memberof V1RecommendationChip
     */
    targetUri?: string;
}

/**
 * Check if a given object implements the V1RecommendationChip interface.
 */
export function instanceOfV1RecommendationChip(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "imageUrl" in value;

    return isInstance;
}

export function V1RecommendationChipFromJSON(json: any): V1RecommendationChip {
    return V1RecommendationChipFromJSONTyped(json, false);
}

export function V1RecommendationChipFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1RecommendationChip {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'imageUrl': json['image_url'],
        'targetUri': !exists(json, 'target_uri') ? undefined : json['target_uri'],
    };
}

export function V1RecommendationChipToJSON(value?: V1RecommendationChip | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'image_url': value.imageUrl,
        'target_uri': value.targetUri,
    };
}

