/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SuggestionKeywordType = {
    Unspecified: 'KEYWORD_TYPE_UNSPECIFIED',
    RootCategory: 'KEYWORD_TYPE_ROOT_CATEGORY',
    SubCategory: 'KEYWORD_TYPE_SUB_CATEGORY'
} as const;
export type SuggestionKeywordType = typeof SuggestionKeywordType[keyof typeof SuggestionKeywordType];


export function SuggestionKeywordTypeFromJSON(json: any): SuggestionKeywordType {
    return SuggestionKeywordTypeFromJSONTyped(json, false);
}

export function SuggestionKeywordTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuggestionKeywordType {
    return json as SuggestionKeywordType;
}

export function SuggestionKeywordTypeToJSON(value?: SuggestionKeywordType | null): any {
    return value as any;
}

