/**
 * url에서 query를 가져옵니다.
 */
export function getQueryFromUrl<T extends Record<string, string | undefined>>(
  url: string
): Partial<T> {
  const result: Partial<T> = {}
  const split = url.split('?')?.[1]

  if (!split) {
    return {}
  }

  const urlParams = new URLSearchParams(url.split('?')?.[1])

  urlParams.forEach((value, key) => {
    result[key as keyof T] = value as T[keyof T]
  })
  return result
}
