/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { ThemeBusinessPostSectionThemeType } from './ThemeBusinessPostSectionThemeType';
import {
    ThemeBusinessPostSectionThemeTypeFromJSON,
    ThemeBusinessPostSectionThemeTypeFromJSONTyped,
    ThemeBusinessPostSectionThemeTypeToJSON,
} from './ThemeBusinessPostSectionThemeType';
import type { V1BusinessPost } from './V1BusinessPost';
import {
    V1BusinessPostFromJSON,
    V1BusinessPostFromJSONTyped,
    V1BusinessPostToJSON,
} from './V1BusinessPost';

/**
 * 
 * @export
 * @interface ThemeBusinessPostSectionThemeBusinessPost
 */
export interface ThemeBusinessPostSectionThemeBusinessPost {
    /**
     * 
     * @type {ThemeBusinessPostSectionThemeType}
     * @memberof ThemeBusinessPostSectionThemeBusinessPost
     */
    themeType: ThemeBusinessPostSectionThemeType;
    /**
     * 
     * @type {Array<V1BusinessPost>}
     * @memberof ThemeBusinessPostSectionThemeBusinessPost
     */
    businessPosts: Array<V1BusinessPost>;
    /**
     * 
     * @type {string}
     * @memberof ThemeBusinessPostSectionThemeBusinessPost
     */
    viewMoreTargetUri: string;
}

/**
 * Check if a given object implements the ThemeBusinessPostSectionThemeBusinessPost interface.
 */
export function instanceOfThemeBusinessPostSectionThemeBusinessPost(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "themeType" in value;
    isInstance = isInstance && "businessPosts" in value;
    isInstance = isInstance && "viewMoreTargetUri" in value;

    return isInstance;
}

export function ThemeBusinessPostSectionThemeBusinessPostFromJSON(json: any): ThemeBusinessPostSectionThemeBusinessPost {
    return ThemeBusinessPostSectionThemeBusinessPostFromJSONTyped(json, false);
}

export function ThemeBusinessPostSectionThemeBusinessPostFromJSONTyped(json: any, ignoreDiscriminator: boolean): ThemeBusinessPostSectionThemeBusinessPost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'themeType': ThemeBusinessPostSectionThemeTypeFromJSON(json['theme_type']),
        'businessPosts': ((json['business_posts'] as Array<any>).map(V1BusinessPostFromJSON)),
        'viewMoreTargetUri': json['view_more_target_uri'],
    };
}

export function ThemeBusinessPostSectionThemeBusinessPostToJSON(value?: ThemeBusinessPostSectionThemeBusinessPost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'theme_type': ThemeBusinessPostSectionThemeTypeToJSON(value.themeType),
        'business_posts': ((value.businessPosts as Array<any>).map(V1BusinessPostToJSON)),
        'view_more_target_uri': value.viewMoreTargetUri,
    };
}

