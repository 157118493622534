import { useBridgeInfo } from '@src/bridge/context/BridgeInfoProvider'
import UserLocationProvider from '@src/local-map-utils/user-location/providers/UserLocationProvider'

interface LocalMapUserLocationProviderProps {
  children: React.ReactNode
}

const LocalMapUserLocationProvider = ({
  children,
}: LocalMapUserLocationProviderProps) => {
  const bridgeInfo = useBridgeInfo()

  return (
    <UserLocationProvider bridgeInfo={bridgeInfo} useLocalStorageCache={true}>
      {children}
    </UserLocationProvider>
  )
}

export default LocalMapUserLocationProvider
