export type SeedColorTheme = 'light' | 'dark'

export const getDocumentSeedScaleColor = () => {
  return window.document.documentElement.dataset
    .seedScaleColor as SeedColorTheme
}

/**
 * Seed Design CSS 변수 값을 가져옵니다.
 * @param variableName ex) vars.$scale.color.gray900
 */
export const getSeedDesignCSSVariableValue = (variableName: string) => {
  const regex = /var\((.+?)\)/
  const matches = variableName.match(regex)

  if (!matches) {
    throw new Error('Invalid CSS variable name')
  }

  return getComputedStyle(document.documentElement)
    .getPropertyValue(matches[1])
    .trim()
}

/**
 * index.html의 SEED_SCALE_COLOR 변경(prefers-color-scheme: dark) 이벤트 이름
 */
export const EVENT_NAME_SEED_SCALE_COLOR_CHANGED = 'onSeedScaleColorChanged'
