import { type ZodSchema, type z } from 'zod'

import {
  HomeStepParamsSchema,
  PreviewStepParamsSchema,
  SearchStepParamsSchema,
  SearchTypingStepParamsSchema,
  SearchedPreviewStepParamsSchema,
  StoryPreviewStepParamsSchema,
} from './params'
import { type LocalMapStepKeys } from './type'

const schemaMap = {
  home: HomeStepParamsSchema,
  searchDiscovery: SearchTypingStepParamsSchema,
  searchResult: SearchStepParamsSchema,
  preview: PreviewStepParamsSchema,
  searchedPreview: SearchedPreviewStepParamsSchema,
  storyPreview: StoryPreviewStepParamsSchema,
} as const satisfies Record<LocalMapStepKeys, ZodSchema>

type SchemaMap = typeof schemaMap
type ParamsType<T extends keyof SchemaMap> = z.infer<SchemaMap[T]>

export const parseParams = <T extends LocalMapStepKeys>(
  stepKey: T,
  params?: object
): ParamsType<T> => {
  const schema = schemaMap[stepKey]
  return schema.parse(params)
}
