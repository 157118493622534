/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SocialCapitalSocialCapitalSeasonStatus = {
    Unspecified: 'SOCIAL_CAPITAL_SEASON_STATUS_UNSPECIFIED',
    InProgress: 'SOCIAL_CAPITAL_SEASON_STATUS_IN_PROGRESS',
    Counting: 'SOCIAL_CAPITAL_SEASON_STATUS_COUNTING',
    Finished: 'SOCIAL_CAPITAL_SEASON_STATUS_FINISHED'
} as const;
export type SocialCapitalSocialCapitalSeasonStatus = typeof SocialCapitalSocialCapitalSeasonStatus[keyof typeof SocialCapitalSocialCapitalSeasonStatus];


export function SocialCapitalSocialCapitalSeasonStatusFromJSON(json: any): SocialCapitalSocialCapitalSeasonStatus {
    return SocialCapitalSocialCapitalSeasonStatusFromJSONTyped(json, false);
}

export function SocialCapitalSocialCapitalSeasonStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocialCapitalSocialCapitalSeasonStatus {
    return json as SocialCapitalSocialCapitalSeasonStatus;
}

export function SocialCapitalSocialCapitalSeasonStatusToJSON(value?: SocialCapitalSocialCapitalSeasonStatus | null): any {
    return value as any;
}

