/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
/**
 * 
 * @export
 * @interface V1LocalStory
 */
export interface V1LocalStory {
    /**
     * 
     * @type {string}
     * @memberof V1LocalStory
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof V1LocalStory
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof V1LocalStory
     */
    thumbnailImageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof V1LocalStory
     */
    distanceDisplayText: string;
    /**
     * 
     * @type {string}
     * @memberof V1LocalStory
     */
    duration: string;
    /**
     * 
     * @type {string}
     * @memberof V1LocalStory
     */
    impressionLoggingUrl: string;
    /**
     * 
     * @type {string}
     * @memberof V1LocalStory
     */
    targetUri: string;
}

/**
 * Check if a given object implements the V1LocalStory interface.
 */
export function instanceOfV1LocalStory(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "thumbnailImageUrl" in value;
    isInstance = isInstance && "distanceDisplayText" in value;
    isInstance = isInstance && "duration" in value;
    isInstance = isInstance && "impressionLoggingUrl" in value;
    isInstance = isInstance && "targetUri" in value;

    return isInstance;
}

export function V1LocalStoryFromJSON(json: any): V1LocalStory {
    return V1LocalStoryFromJSONTyped(json, false);
}

export function V1LocalStoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1LocalStory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': json['description'],
        'thumbnailImageUrl': json['thumbnail_image_url'],
        'distanceDisplayText': json['distance_display_text'],
        'duration': json['duration'],
        'impressionLoggingUrl': json['impression_logging_url'],
        'targetUri': json['target_uri'],
    };
}

export function V1LocalStoryToJSON(value?: V1LocalStory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'thumbnail_image_url': value.thumbnailImageUrl,
        'distance_display_text': value.distanceDisplayText,
        'duration': value.duration,
        'impression_logging_url': value.impressionLoggingUrl,
        'target_uri': value.targetUri,
    };
}

