import { type BridgeInfoType } from '@src/bridge/context/BridgeInfoProvider'
import { IS_PRODUCTION } from '@src/constants'
import { Configuration, LocalMapService } from '@src/network/src'
import { makePlantaeFetch } from '@src/plantae/makePlantaeFetch'

const BASE_PATH = IS_PRODUCTION
  ? LocalMapService.BASE_PATH.replace('.alpha.', '.')
  : LocalMapService.BASE_PATH

export const localMapApi = (bridgeInfo: BridgeInfoType) => {
  const config = new Configuration({
    basePath: BASE_PATH,
    fetchApi: makePlantaeFetch(bridgeInfo),
  })

  return new LocalMapService.LocalMapServiceApi(config)
}
