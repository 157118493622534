/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { NativeRecommendationLocalProfilesRankingSectionLocalProfileRanking } from './NativeRecommendationLocalProfilesRankingSectionLocalProfileRanking';
import {
    NativeRecommendationLocalProfilesRankingSectionLocalProfileRankingFromJSON,
    NativeRecommendationLocalProfilesRankingSectionLocalProfileRankingFromJSONTyped,
    NativeRecommendationLocalProfilesRankingSectionLocalProfileRankingToJSON,
} from './NativeRecommendationLocalProfilesRankingSectionLocalProfileRanking';

/**
 * 
 * @export
 * @interface V1NativeRecommendationLocalProfilesRankingSection
 */
export interface V1NativeRecommendationLocalProfilesRankingSection {
    /**
     * 
     * @type {Array<NativeRecommendationLocalProfilesRankingSectionLocalProfileRanking>}
     * @memberof V1NativeRecommendationLocalProfilesRankingSection
     */
    localProfileRankings: Array<NativeRecommendationLocalProfilesRankingSectionLocalProfileRanking>;
}

/**
 * Check if a given object implements the V1NativeRecommendationLocalProfilesRankingSection interface.
 */
export function instanceOfV1NativeRecommendationLocalProfilesRankingSection(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "localProfileRankings" in value;

    return isInstance;
}

export function V1NativeRecommendationLocalProfilesRankingSectionFromJSON(json: any): V1NativeRecommendationLocalProfilesRankingSection {
    return V1NativeRecommendationLocalProfilesRankingSectionFromJSONTyped(json, false);
}

export function V1NativeRecommendationLocalProfilesRankingSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1NativeRecommendationLocalProfilesRankingSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'localProfileRankings': ((json['local_profile_rankings'] as Array<any>).map(NativeRecommendationLocalProfilesRankingSectionLocalProfileRankingFromJSON)),
    };
}

export function V1NativeRecommendationLocalProfilesRankingSectionToJSON(value?: V1NativeRecommendationLocalProfilesRankingSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'local_profile_rankings': ((value.localProfileRankings as Array<any>).map(NativeRecommendationLocalProfilesRankingSectionLocalProfileRankingToJSON)),
    };
}

