import { createContext } from 'react'

import {
  type CreateUserLocationResponse,
  type CreateUserLocationProps,
} from '../api/createUserLocation'
import {
  type DeleteUserLocationResponse,
  type DeleteUserLocationProps,
} from '../api/deleteUserLocation'
import {
  type GetUserLocationProps,
  type GetUserLocationResponse,
} from '../api/getUserLocation'
import {
  type UpdateUserLocationResponse,
  type UpdateUserLocationProps,
} from '../api/updateUserLocation'

export type UnpackPromise<T> = T extends Promise<infer U> ? U : T

type APIEvents<T> = {
  onSuccess?: (response: UnpackPromise<T>) => void
  onError?: (error: Error) => void
}

// 기존 API 타입에서 bridgeInfo만 제외. bridgeInfo는 Provider를 통해 주입 받음
export type UserLocationAPIProps<T, R> = Omit<T, 'bridgeInfo'> & APIEvents<R>

export type UserLocationAPIType = {
  refetchUserLocation: (
    props: UserLocationAPIProps<GetUserLocationProps, GetUserLocationResponse>
  ) => void
  createUserLocation: (
    props: UserLocationAPIProps<
      CreateUserLocationProps,
      CreateUserLocationResponse
    >
  ) => void
  updateUserLocation: (
    props: UserLocationAPIProps<
      UpdateUserLocationProps,
      UpdateUserLocationResponse
    >
  ) => void
  deleteUserLocation: (
    props: UserLocationAPIProps<
      DeleteUserLocationProps,
      DeleteUserLocationResponse
    >
  ) => void
}

// Provider를 통해 무조건 할당 될 것이기 때문에 null as any로 초기화
const UserLocationAPIContext = createContext<UserLocationAPIType>(null as any)

export default UserLocationAPIContext
