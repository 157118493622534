import type { Plugin } from 'plantae'

const plantaeKarrotRegionIdPlugin = ({
  regionId,
}: {
  regionId: number
}): Plugin => {
  return {
    name: 'plugin-karrot-region-id',
    hooks: {
      beforeRequest: async (req) => {
        req.headers.set('x-karrot-region-id', regionId.toString())

        return req
      },
    },
  }
}

export default plantaeKarrotRegionIdPlugin
