import { type LocalMapStepKeys } from '@src/hooks/useStepRouter'
import { MAP_TOP_AREA_SIZE_MAPPER } from '@src/pages/home-activity/_constants/size'
import { type Anchor } from '@src/react-utils/bottom-sheet/types'

const HeightViewTypes = ['small', 'medium', 'large'] as const
export type HeightViewType = (typeof HeightViewTypes)[number]

export type LocalMapBottomSheetAnchorKeys = Record<LocalMapStepKeys, Anchor[]>
const LocalMapBottomSheetAnchorIds = ['top', 'middle', 'bottom'] as const
export type LocalMapBottomSheetAnchorId =
  (typeof LocalMapBottomSheetAnchorIds)[number]

const BOTTOM_SHEET_HANDLE_BAR_HEIGHT_PX = 24

export const ANCHOR_SEARCH_BOTTOM_OFFSET = 40
export const ANCHOR_SEARCH_TOP_OFFSET =
  MAP_TOP_AREA_SIZE_MAPPER.searchResult - BOTTOM_SHEET_HANDLE_BAR_HEIGHT_PX

const BOTTOM_SHEET_MIN_HEIGHT = 260
const BOTTOM_SHEET_MAX_HEIGHT = 360

export const getMiddleOffset = () => {
  const value = Math.floor(window.innerHeight * 0.4)
  const clampValue = Math.min(
    BOTTOM_SHEET_MAX_HEIGHT,
    Math.max(BOTTOM_SHEET_MIN_HEIGHT, value)
  )
  return clampValue
}

const getSmallHeightBottomSheetAnchor = (
  stepType: LocalMapStepKeys
): Anchor[] => {
  switch (stepType) {
    default:
    case 'home':
      return [
        {
          id: 'top',
          edge: 'top',
          offset: 10,
          scrollBehavior: 'scrollContent',
          sizingMode: 'fixed',
        },
        {
          id: 'bottom',
          edge: 'bottom',
          offset: 40,
          scrollBehavior: 'dragSheet',
          sizingMode: 'fixed',
        },
      ]
    case 'searchResult':
      return [
        {
          id: 'top',
          edge: 'top',
          offset: ANCHOR_SEARCH_TOP_OFFSET,
          scrollBehavior: 'scrollContent',
          sizingMode: 'fixed',
        },
        {
          id: 'bottom',
          edge: 'bottom',
          offset: ANCHOR_SEARCH_BOTTOM_OFFSET,
          scrollBehavior: 'dragSheet',
          sizingMode: 'fixed',
        },
      ]
    case 'searchDiscovery':
      return [
        {
          id: 'bottom',
          edge: 'bottom',
          offset: 0,
          scrollBehavior: 'dragSheet',
          sizingMode: 'fixed',
        },
      ]
    case 'storyPreview':
      return [
        {
          id: 'top',
          edge: 'top',
          offset: 72,
          scrollBehavior: 'scrollContent',
          sizingMode: 'fixed',
        },
        {
          id: 'bottom',
          edge: 'bottom',
          offset: 60,
          scrollBehavior: 'dragSheet',
          sizingMode: 'fixed',
        },
      ]
  }
}

const getMediumHeightBottomSheetAnchor = (
  stepType: LocalMapStepKeys
): Anchor[] => {
  switch (stepType) {
    default:
    case 'home':
      return [
        {
          id: 'top',
          edge: 'top',
          offset: 72,
          scrollBehavior: 'scrollContent',
          sizingMode: 'fixed',
        },
        {
          id: 'bottom',
          edge: 'bottom',
          offset: 40,
          scrollBehavior: 'dragSheet',
          sizingMode: 'fixed',
        },
      ]
    case 'searchResult':
      return [
        {
          id: 'top',
          edge: 'top',
          offset: ANCHOR_SEARCH_TOP_OFFSET,
          scrollBehavior: 'scrollContent',
          sizingMode: 'fixed',
        },
        {
          id: 'bottom',
          edge: 'bottom',
          offset: ANCHOR_SEARCH_BOTTOM_OFFSET,
          scrollBehavior: 'dragSheet',
          sizingMode: 'fixed',
        },
      ]
    case 'searchDiscovery':
      return [
        {
          id: 'bottom',
          edge: 'bottom',
          offset: 0,
          scrollBehavior: 'dragSheet',
          sizingMode: 'fixed',
        },
      ]
    case 'storyPreview':
      return [
        {
          id: 'top',
          edge: 'top',
          offset: 72,
          scrollBehavior: 'scrollContent',
          sizingMode: 'fixed',
        },
        {
          id: 'bottom',
          edge: 'bottom',
          offset: 60,
          scrollBehavior: 'dragSheet',
          sizingMode: 'fixed',
        },
      ]
  }
}

const getLargeHeightBottomSheetAnchor = (
  stepType: LocalMapStepKeys
): Anchor[] => {
  switch (stepType) {
    default:
    case 'home':
      return [
        {
          id: 'top',
          edge: 'top',
          offset: 72,
          scrollBehavior: 'scrollContent',
          sizingMode: 'fixed',
        },
        {
          id: 'middle',
          edge: 'bottom',
          offset: getMiddleOffset(),
          scrollBehavior: 'dragSheet',
          sizingMode: 'fixed',
        },
        {
          id: 'bottom',
          edge: 'bottom',
          offset: 60,
          scrollBehavior: 'dragSheet',
          sizingMode: 'fixed',
        },
      ]
    case 'searchResult':
      return [
        {
          id: 'top',
          edge: 'top',
          offset: ANCHOR_SEARCH_TOP_OFFSET,
          scrollBehavior: 'scrollContent',
          sizingMode: 'fixed',
        },
        {
          id: 'middle',
          edge: 'bottom',
          offset: Math.floor(window.innerHeight * 0.35),
          scrollBehavior: 'dragSheet',
          sizingMode: 'fixed',
        },
        {
          id: 'bottom',
          edge: 'bottom',
          offset: ANCHOR_SEARCH_BOTTOM_OFFSET,
          scrollBehavior: 'dragSheet',
          sizingMode: 'fixed',
        },
      ]
    case 'searchDiscovery':
      return [
        {
          id: 'bottom',
          edge: 'bottom',
          offset: 0,
          scrollBehavior: 'dragSheet',
          sizingMode: 'fixed',
        },
      ]
    case 'storyPreview':
      return [
        {
          id: 'top',
          edge: 'top',
          offset: 72 + 100,
          scrollBehavior: 'scrollContent',
          sizingMode: 'fixed',
        },
        {
          id: 'middle',
          edge: 'bottom',
          offset: 280,
          scrollBehavior: 'dragSheet',
          sizingMode: 'fixed',
        },
        {
          id: 'bottom',
          edge: 'bottom',
          offset: 60,
          scrollBehavior: 'dragSheet',
          sizingMode: 'fixed',
        },
      ]
  }
}

export const getBottomSheetAnchors = ({
  heightViewType,
  stepType,
}: {
  heightViewType: HeightViewType
  stepType: LocalMapStepKeys
}): Anchor[] => {
  switch (heightViewType) {
    case 'small':
      return getSmallHeightBottomSheetAnchor(stepType)
    case 'medium':
      return getMediumHeightBottomSheetAnchor(stepType)
    case 'large':
      return getLargeHeightBottomSheetAnchor(stepType)
    default:
      return getMediumHeightBottomSheetAnchor(stepType)
  }
}

export const getBottomSheetAnchorById = ({
  anchorId,
  heightViewType,
  stepType,
}: {
  anchorId: LocalMapBottomSheetAnchorId
  heightViewType: HeightViewType
  stepType: LocalMapStepKeys
}): Anchor | undefined => {
  const anchors = getBottomSheetAnchors({ heightViewType, stepType })
  return anchors.find((anchor) => anchor.id === anchorId)
}

export const getBottomSheetInitialAnchorId = (
  heightViewType: HeightViewType
): LocalMapBottomSheetAnchorId => {
  switch (heightViewType) {
    case 'large':
      return 'middle'
    case 'medium':
    case 'small':
      return 'bottom'
    default:
      return 'middle'
  }
}
