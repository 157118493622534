/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { V1RecommendationChip } from './V1RecommendationChip';
import {
    V1RecommendationChipFromJSON,
    V1RecommendationChipFromJSONTyped,
    V1RecommendationChipToJSON,
} from './V1RecommendationChip';

/**
 * 
 * @export
 * @interface V1ListRecommendationChipsResponse
 */
export interface V1ListRecommendationChipsResponse {
    /**
     * 
     * @type {Array<V1RecommendationChip>}
     * @memberof V1ListRecommendationChipsResponse
     */
    recommendationChips: Array<V1RecommendationChip>;
}

/**
 * Check if a given object implements the V1ListRecommendationChipsResponse interface.
 */
export function instanceOfV1ListRecommendationChipsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "recommendationChips" in value;

    return isInstance;
}

export function V1ListRecommendationChipsResponseFromJSON(json: any): V1ListRecommendationChipsResponse {
    return V1ListRecommendationChipsResponseFromJSONTyped(json, false);
}

export function V1ListRecommendationChipsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ListRecommendationChipsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recommendationChips': ((json['recommendation_chips'] as Array<any>).map(V1RecommendationChipFromJSON)),
    };
}

export function V1ListRecommendationChipsResponseToJSON(value?: V1ListRecommendationChipsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recommendation_chips': ((value.recommendationChips as Array<any>).map(V1RecommendationChipToJSON)),
    };
}

