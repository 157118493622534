import {
  type SearchFunnelIdAction,
  type SearchFunnelIdState,
  searchFunnelIdReducer,
  initialSearchFunnelIdState,
} from '@daangn/search-log-sdk'
import { type StateCreator } from 'zustand'

interface SearchFunnelIdStore extends SearchFunnelIdState {
  dispatchSearchFunnelId: (action: SearchFunnelIdAction) => void
}

export const searchFunnelIdStore: StateCreator<SearchFunnelIdStore> = (
  set
) => ({
  searchFunnelId: initialSearchFunnelIdState.searchFunnelId,
  dispatchSearchFunnelId: (action: SearchFunnelIdAction) =>
    set((state) => searchFunnelIdReducer(state, action)),
})
