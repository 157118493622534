import { useMemo } from 'react'

import { getQueryFromUrl } from '@src/ts-utils/getQueryFromUrl'

export function useQueryParams<
  T extends Record<string, never> | Record<string, string>,
>(): Partial<T> {
  return useMemo(() => {
    return getQueryFromUrl(window.location.href)
  }, [])
}
