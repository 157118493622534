import authPlugin from '@daangn/plantae-plugin-auth'

import { karrotBridge } from '@src/bridge/bridge'
import { clientEnvironment } from '@src/bridge/utils'

/**
 * @see https://github.com/daangn/webview-network/tree/main/packages/plantae-plugin-auth
 */
const plantaeAuthPlugin = ({
  fallbackAuthToken,
}: {
  fallbackAuthToken: string
}) => {
  const isApp = clientEnvironment.isAndroid || clientEnvironment.isIOS
  return authPlugin({
    bridge: karrotBridge,
    options: {
      fallbackAuthToken: isApp || {
        value: fallbackAuthToken ?? undefined, // local 환경 테스트
      },
    },
  })
}

export default plantaeAuthPlugin
