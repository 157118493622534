import { create } from 'zustand'

import { type TypeLatLng } from '@src/network/src/__gen__/local-map'

export interface DistancedCoordinatesTypes {
  type: 'userLocation' | 'currentPosition'
  coordinates: TypeLatLng
}

interface coordinatesStoreProps {
  distancedCoordinates: DistancedCoordinatesTypes | null
  setDistancedCoordinates: (
    coordinates: DistancedCoordinatesTypes | null
  ) => void
  region3Name: string
  setRegion3Name: (region: string) => void
  sectionImpressReset: number
  setSectionImpressReset: () => void
  isBottomSheetShow: number
  setIsBottomSheetShow: () => void
}

export const coordinatesStore = create<coordinatesStoreProps>((set) => ({
  distancedCoordinates: null,
  setDistancedCoordinates: (
    distancedCoordinates: DistancedCoordinatesTypes | null
  ) => set({ distancedCoordinates: distancedCoordinates }),
  region3Name: '',
  setRegion3Name: (region: string) => set({ region3Name: region }),
  sectionImpressReset: 0,
  setSectionImpressReset: () =>
    set((state) => ({
      sectionImpressReset: state.sectionImpressReset + 1,
    })),
  isBottomSheetShow: 0,
  setIsBottomSheetShow: () =>
    set((state) => ({
      isBottomSheetShow: state.isBottomSheetShow + 1,
    })),
}))
