import { getDeviceType } from '@src/ts-utils/deviceType'

const ANDROID_ONLY_SAFE_AREA_COOKIE_KEY = 'safeAreaInsetTopOverride'
const IOS_ONLY_SAFE_AREA_CACHE_KEY = 'cached-safe-area-inset-top'
/**
 * Android Status bar height를 cookie에서 가져옵니다.
 * Tech Spec: https://www.notion.so/daangn/Tech-Spec-Safe-Area-iOS-safe-area-3be409a6d04b46c79ff86189b0e1a159
 */
export const ANDROID_ONLY_getSafeAreaInsetTopOverrideValue = () => {
  const safeAreaInsetTopOverrideCookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${ANDROID_ONLY_SAFE_AREA_COOKIE_KEY}=`))
    ?.split('=')[1]

  return safeAreaInsetTopOverrideCookie ?? null
}

export const ANDROID_ONLY_safeAreaInsetTopOverride = () => {
  const overrideValue = ANDROID_ONLY_getSafeAreaInsetTopOverrideValue()

  if (!overrideValue) {
    return
  }

  document.documentElement.style.setProperty(
    '--safe-area-inset-top',
    `${overrideValue}px`
  )
}

/**
 * Prevent layout shifting by safe-area-inset-top
 * issue: https://bugs.webkit.org/show_bug.cgi?id=191872
 * Tech Spec: https://www.notion.so/daangn/Tech-Spec-Webview-Entry-Loading-FCP-48afd5f704d347a991be56b48be74df1 (iOS safe-area 캐싱 참고)
 * 현재 홈에서만(/home) 적용
 */
export const IOS_ONLY_safeAreaInsetTopOverride = () => {
  IOS_ONLY_setSafeAreaCaching()

  const cachedSafeAreaInsetTop = getCachedIOSSafeAreaInsetTop()

  if (
    cachedSafeAreaInsetTop &&
    cachedSafeAreaInsetTop !== '0px' &&
    parseInt(cachedSafeAreaInsetTop) > 0
  ) {
    // 캐싱값과 env(safe-area-value) 중에서 큰 값으로 선택
    const overrideValue = Math.max(
      parseInt(cachedSafeAreaInsetTop),
      parseInt(
        getComputedStyle(document.documentElement).getPropertyValue('--sat') // env(safe-area-inset-top)
      )
    )

    document.documentElement.style.setProperty(
      '--safe-area-inset-top',
      `${overrideValue}px`
    )
  }
}

const IOS_ONLY_setSafeAreaCaching = () => {
  const isHomePath = !!window.location.pathname.startsWith('/home')

  if (isHomePath) {
    setTimeout(() => {
      const safeAreaInsetTop = getComputedStyle(
        document.documentElement
      ).getPropertyValue('--sat')

      if (safeAreaInsetTop !== '0px' && parseInt(safeAreaInsetTop) > 0) {
        localStorage.setItem(IOS_ONLY_SAFE_AREA_CACHE_KEY, safeAreaInsetTop)
      }
    }, 2000)
  }
}

export const setSafeAreaValues = () => {
  const deviceType = getDeviceType()

  switch (deviceType) {
    case 'android':
      ANDROID_ONLY_safeAreaInsetTopOverride()
      break
    case 'ios':
      IOS_ONLY_safeAreaInsetTopOverride()
      break
  }
}

export const getCachedIOSSafeAreaInsetTop = () =>
  localStorage.getItem(IOS_ONLY_SAFE_AREA_CACHE_KEY)
